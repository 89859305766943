@import 'src/styles/mediaQueries';

.Title {
  margin: 0 auto;
  color: var(--color-text-title);
  font: 900 3.5rem / 1 'Open Sans', sans-serif;
  line-height: 4rem;
  text-align: center;
  text-transform: uppercase;
}

.Title_small {
  padding: 0 1.7rem;
  border-bottom: 1px solid var(--color-border);
  margin-top: 8.2rem;
  font-size: 2.2rem;
  line-height: 2;
  text-align: left;
}

.Title_red {
  color: var(--color-brand-main);
}

.Title_mobile {
  padding-bottom: 2rem;
  line-height: 2.5rem;
}

@include screens-above('xs') {
  .Title {
    font-size: 3.7rem;
  }
}

@include screens-above('md') {
  .Title {
    font-size: 4.7rem;
    letter-spacing: -0.3rem;
  }

  .Title_small {
    font-size: 2.2rem;
    letter-spacing: 0;
  }
}
