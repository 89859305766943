.RadioButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: var(--red-60);
    cursor: pointer;
  }
}

.RadioButtonContainer input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.RadioButton {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--grey-400);
  margin-right: 0.8rem;
  background: transparent;
  border-radius: 50%;
}

.RadioButton::after {
  position: absolute;
  display: none;
  content: '';
}

.RadioButtonContainer input:checked ~ .RadioButton::after {
  display: flex;
  width: 100%;
  height: 100%;
  border: 3px solid var(--white);
  background: var(--red-60);
  border-radius: 100%;
}

.RadioButtonContainer input:disabled ~ .RadioButton::after {
  display: block;
  background: var(--grey-300);
}

.RadioButtonContainer input:disabled ~ .RadioButton {
  border: 1px solid var(--grey-300);
}

.RadioButtonIconContainer {
  max-width: 2rem;
  max-height: 2rem;
  margin-right: 0.8rem;
  float: left;
}

.RadioButtonLabel {
  &:hover {
    color: var(--red-60);
  }
}
