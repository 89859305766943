.CommentContainer {
  padding: 0 1.6rem 1.2rem 1.6rem;
  border-bottom: 1px solid var(--color-disabled-background);
  margin-top: 1.6rem;
}

.AvatarContainer {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.8rem;
  float: left;
}

.CommentTextContainer {
  margin-top: 1.2rem;
}

.CommentText {
  color: var(--color-text-title);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.CommentName {
  color: var(--color-text-main);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.Date {
  color: var(--color-button-gray);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}
