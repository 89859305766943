.Wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.Separator {
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
  background-color: var(--red-60);

  &.Inactive {
    background-color: var(--grey-300);
  }
}

.ItemWrapper {
  width: 100%;
}
