@import 'src/styles/mediaQueries';

.Regular,
.Bold,
.Label,
.UiRegular,
.UiBold {
  margin: 0;
  color: var(--color-text-main);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.7rem;
}

.Bold,
.UiBold,
.Label {
  font-weight: bold;
}

.Label {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.Small {
  margin: 0;
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 1.4rem;
}

.UiRegular {
  margin: 0;
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 1.4rem;
}

.UiRegular,
.UiBold {
  font-size: 1.4rem;
  line-height: 2rem;
}

.DisplayBlock {
  display: block;
}

.DisplayInlineBlock {
  display: inline-block;
}

@include screens-below('lg') {
  .Label {
    text-align: center;
  }
}
