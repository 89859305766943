@import 'src/styles/zIndex';

.Wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.RibbonArea {
  position: absolute;
  z-index: z('ribbon');
  width: fit-content;
  height: fit-content;

  &.RibbonAreaPlacementRightTop {
    top: 0;
    right: 0;
    transform: rotate(0deg);
  }

  &.RibbonAreaPlacementLeftTop {
    top: -2px;
    left: 3px;
    transform: rotate(-90deg);
  }
}

.Ribbon {
  position: relative;
  width: 84px;
  height: 84px;

  &.RibbonSoldOut {
    & path {
      fill: var(--grey-600);
    }
  }

  &.RibbonComingSoon {
    & path {
      fill: var(--featured-60);
    }
  }

  &.RibbonUnavailable {
    & path {
      fill: var(--grey-400);
    }
  }

  &.RibbonHistoric {
    & path {
      fill: var(--grey-200);
    }
  }
}

.Label {
  position: absolute;
  width: fit-content;
  height: fit-content;

  &.LabelSoldOut.LabelPlacementRightTop {
    top: 23px;
    left: 15px;
    margin: 0;
    color: var(--white);
    transform: rotate(45deg);
  }

  &.LabelSoldOut.LabelPlacementLeftTop {
    top: 23px;
    left: 15px;
    margin: 0;
    color: var(--white);
    transform: rotate(45deg);
  }

  &.LabelComingSoon.LabelPlacementRightTop {
    bottom: 46px;
    left: 24px;
    margin: 0;
    color: var(--white);
    transform: rotate(45deg);
  }

  &.LabelComingSoon.LabelPlacementLeftTop {
    bottom: 46px;
    left: 24px;
    margin: 0;
    color: var(--white);
    transform: rotate(45deg);
  }

  &.LabelUnavailable.LabelPlacementRightTop {
    bottom: 49px;
    left: 12px;
    margin: 0;
    color: var(--white);
    font-size: 10px;
    transform: rotate(45deg);
  }

  &.LabelUnavailable.LabelPlacementLeftTop {
    bottom: 49px;
    left: 12px;
    margin: 0;
    color: var(--white);
    font-size: 10px;
    transform: rotate(45deg);
  }

  &.LabelHistoric.LabelPlacementRightTop {
    bottom: 45px;
    left: 19px;
    margin: 0;
    color: var(--black);
    transform: rotate(45deg);
  }

  &.LabelHistoric.LabelPlacementLeftTop {
    bottom: 45px;
    left: 19px;
    margin: 0;
    color: var(--black);
    transform: rotate(45deg);
  }
}
