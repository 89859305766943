.CheckboxContainer {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: var(--red-60);
    cursor: pointer;
  }
}

.CheckboxContainer input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.Checkbox {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--grey-400);
  margin-right: 0.8rem;
  background: transparent;
  border-radius: 4px;
}

.Checkbox::after {
  position: absolute;
  display: none;
  content: '';
}

.CheckboxContainer input:checked ~ .Checkbox::after {
  display: block;
}

.CheckboxContainer input:checked:disabled ~ .Checkbox::after {
  display: block;
  border: solid var(--grey-300);
  border-width: 0 2px 2px 0;
}

.CheckboxContainer input:disabled ~ .Checkbox::after {
  display: block;
}

.CheckboxContainer input:disabled ~ .Checkbox {
  border: 1px solid var(--grey-300);
}

.CheckboxContainer .Checkbox::after {
  top: 3px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid var(--red-60);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.CheckboxIconContainer {
  max-width: 2rem;
  max-height: 2rem;
  margin-right: 0.8rem;
  float: left;
}

.CheckboxLabel {
  &:hover {
    color: var(--red-60);
  }
}
