@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.NewPostContainer {
  padding: 1.6rem;
  border: 1px solid var(--color-gray);
  background-color: var(--color-bg-2);
}

.NewPostContainer.ShowMobile {
  position: fixed;
  z-index: z('modal', 'lvl-1');
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.NewPostContainer :global(.sb-avatar) {
  align-self: baseline;
}

.NewPostContainer + button {
  margin: 0 auto 3rem;
}

.MainFeedControls {
  display: flex;
  flex-wrap: wrap;
}

.PostAvatar {
  margin-right: 0.8rem;
}

.Textarea {
  display: flex;
  width: 80%;
  align-items: center;
  border: 0;
  margin-top: 0.8rem;
  background-color: var(--color-bg-2);
  color: var(--color-text-main);
  font: 400 1.4rem 'Source Sans Pro', sans-serif;
  outline: 0;
  resize: none;
}

.Textarea[contenteditable='true']:empty::before {
  display: block;
  color: #aaa;
  content: attr(placeholder);
}

.PinnedPostTitleInput {
  flex: 1;
  padding: 0.8rem;
  border: 0;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1;
  outline: none;
}

.Textarea:focus,
.PinnedPostTitleInput:focus {
  border: 0;
  outline: 1px solid var(--color-brand-main) !important;
}

.NewPostContainer.ShowMobile .Textarea {
  overflow: auto;
  width: 100%;
  height: 20vh;
  align-items: start;
  padding: 0.8rem;
  border: 1px solid var(--color-border);
  margin: 1.6rem 0;
}

.ErrorContainer {
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: var(--color-brand-main);
  color: var(--color-white);
  font: 1.1rem 'Open Sans', sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.MainFeedActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.6rem;

  @include screens-above('lg') {
    flex-direction: row;
  }

  @include screens-below('md') {
    position: fixed;
    bottom: 3.5rem;
  }
}

.MainFeedActions button {
  position: relative;
  height: 3.8rem;
}

.ActionsBlock {
  display: inherit;
  width: 70%;
}

.ActionsBlockMobile {
  display: flex;
  padding-top: 1rem;
  background-color: var(--color-bg-2);
}

.ButtonsBlock {
  display: flex;
  margin-top: 1.6rem;

  @include screens-above('lg') {
    margin-top: 0;
  }

  button:first-of-type {
    margin-right: 0.8rem;

    @include screens-above('lg') {
      margin-right: 0;
    }
  }

  button:nth-child(2) {
    margin-left: 0.8rem;

    @include screens-above('lg') {
      margin-left: 0;
    }
  }
}

.ButtonsBlock button {
  flex: 1;
}

.ButtonsBlock button:not(:last-child) {
  margin-right: 0.8rem;
}

.ButtonSecondary {
  border: 0;
  background-color: var(--color-bg-1);
  color: var(--color-text-title);
  outline: 0;
}

.PostActionButton {
  z-index: z('base');
  display: flex;
  min-width: 3.8rem;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray);
  margin-right: 0.8rem;
  background-color: transparent;
  outline: 0;

  path {
    fill: var(--color-border-gray);
  }

  :global(.raf-emoji-picker__container) {
    position: fixed;
    top: 15rem;

    :global(.emoji-mart-search-icon) {
      position: absolute;
      z-index: 2;
      top: -0.5rem;
      right: 0.5rem;
      padding: 0.2rem 0.5rem 0.1rem;
      border: none;
      background: none;
    }

    @include screens-above('lg') {
      position: absolute;
      top: unset;
    }
  }
}

.PostActionButton.Selected {
  border: 1px solid var(--color-brand-main);
  background: var(--color-brand-border);
}

.PostActionButton svg {
  width: 2rem;
  fill: var(--color-button-gray);
  vertical-align: middle;
}

.PostActionButton.Selected svg {
  fill: var(--color-brand-main);
}

.MainFeedActions button:disabled {
  background-color: var(--color-button-gray);
  color: var(--color-disabled-text);
}

@include screens-above('lg') {
  .Textarea {
    width: 90%;
    padding: 0.8rem;
    margin: 0;
  }

  .PinnedPostTitleInput {
    margin-bottom: 1.6rem;
  }
}
