@import 'src/styles/mediaQueries';

.Title {
  padding: 2rem 2rem;
  margin: 0 auto;
  font: 400 1.5rem / 1.5 'Open Sans', sans-serif;
  letter-spacing: 0;
  text-transform: none;
}

.TitleWrapper {
  width: 100%;
}

@include screens-above('md') {
  .Title {
    display: flex;
    flex-direction: column;
    padding: 5rem 2rem;
    font-size: 2rem;
  }
}
