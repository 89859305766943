@import 'src/styles/mediaQueries';

.p {
  color: var(--grey-500);
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  line-height: 1.5;
}

.p--variant--bold {
  font-size: 1.7rem;
  font-weight: 700;
}

.p--variant--regular {
  font-size: 1.7rem;
  font-weight: 400;
}

.p--variant--ui-bold {
  font-size: 1.4rem;
  font-weight: 700;
}

.p--variant--ui-regular {
  font-size: 1.4rem;
  font-weight: 400;
}

.p--variant--label {
  font-family: 'Prompt', sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
