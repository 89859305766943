@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.icon {
  padding: 0;
  border: 0;
  background-color: transparent;

  svg {
    fill: var(--color-text-main);
  }
}

.container {
  height: 2.4rem;
  margin-right: 2rem;
}

.contentWrapper {
  position: fixed;
  z-index: z('base');
  top: -2.6rem;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: 100vh;
  border: 0.1rem solid var(--color-border);
  margin-top: 2.6rem;
  background-color: var(--color-bg-2);
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.12);

  @include screens-above('sm') {
    & {
      position: absolute;
      top: 3.4rem;
      right: 1.6rem;
      left: auto;
      width: 100%;
      max-width: 32rem;
      height: calc(100vh - 9.5rem);
    }
  }
}

.content {
  height: 94%;
  padding: 2.4rem;
}

.backButton {
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--color-disabled-text);
  color: var(--color-button-gray);
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2rem;
  outline: 0;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-right: 0.9rem;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
  color: var(--color-text-title);
  font-size: 1.4rem;
  text-transform: uppercase;
}

.headerAction {
  border: 0;
  background-color: transparent;
  color: var(--color-button-gray);
  font-size: 1.4rem;
  font-weight: bold;
  outline: 0;
  transition: filter 0.2s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
}

.divider {
  border-bottom: 0.1rem solid var(--color-gray);
  margin: 1.6rem 0;
}

.list {
  height: 100%;
  overflow-y: scroll;
}
