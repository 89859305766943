.MenuSection {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
}

.Links {
  background-color: transparent;

  &:last-of-type {
    margin-bottom: 0.6rem;
  }
}

.Link {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.7rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.Link:hover {
  background: var(--color-bg-nav-active);

  > div {
    color: var(--color-text-nav-active);
  }
}

.LinkImage {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
}

.LinkText {
  margin-left: 1.2rem;
  color: var(--color-text-main);
  font-size: 1.4rem;
}

.UnleashText {
  color: var(--color-text-title);
  font: 700 1.4rem/1.5 'Source Sans Pro', sans-serif;
}

.Link *[stroke='#5A5665'],
.Link *[stroke='#CECCD1'] {
  stroke: var(--color-nav-icon);
}

.Link *[fill='#5A5665'] {
  fill: var(--color-nav-icon);
}

.Link:hover *[stroke='#5A5665'],
.Link:hover *[stroke='#CECCD1'],
.Link:hover *[stroke='#F63A55'] {
  stroke: var(--color-text-nav-active);
}

.Link:hover *[fill='#5A5665'],
.Link:hover *[fill='#CECCD1'],
.Link:hover *[fill='#F63A55'] {
  fill: var(--color-text-nav-active);
}

.AddInfo {
  padding: 0.8rem 1.7rem;
  border: 1px solid var(--color-border);
  border-width: 1px 0;
  margin-top: 1.8rem;
}

.AddInfoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.AddInfoItem > div {
  display: flex;
  align-items: center;
}

.AddInfoItem svg {
  margin-left: 0.3rem;
}

.CoinAnimation {
  max-width: 1.6rem;
  margin-left: 0.4rem;
}
