@import 'src/styles/mediaQueries';

.PostComment {
  display: flex;

  .Field {
    position: relative;
    display: flex;
    width: 100%;
    margin: 1rem 0 0 0;

    textarea {
      width: 100%;
      padding: 2.2rem 4.8rem 2.2rem 5.6rem;
      border: 0.1rem solid var(--color-light-gray);
      margin: 0;
      background-color: var(--color-lighter-bg);
      color: var(--color-text-main);
      resize: none;

      & > p {
        color: var(--color-text-main);
        font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
      }

      & ~ .AvatarContainer {
        position: absolute;
        top: 1.6rem;
        left: 1.6rem;
        height: 3.2rem;
      }

      & ~ .PostButton {
        display: none;
      }

      & ~ .Icon {
        position: absolute;
        top: 2rem;
        right: 1.6rem;
      }

      & ~ .EmojiPickerButton {
        display: none;
      }
    }

    .FocusTextarea {
      width: 100%;
      min-height: 12rem;
      padding: 2.2rem 1.6rem 5.6rem 5.6rem;
      margin: 0;
      resize: none;

      & ~ .Icon {
        display: none;
      }

      & ~ .EmojiPickerButton {
        position: absolute;
        right: 14rem;
        bottom: 1.6rem;
        display: flex;
        width: 4rem;
        height: 4rem;
        align-items: center;
        border: 0.1rem solid var(--color-light-gray);

        &:focus {
          outline: 1px solid var(--color-brand-main) !important;
        }

        :global(.raf-emoji-picker) {
          width: 100%;
          height: 100%;
        }

        :global(.raf-emoji-picker__button) {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          svg > path {
            fill: var(--color-button-gray);
          }
        }

        :global(.raf-emoji-picker__container) {
          top: 4rem;
          right: -8.5rem;
          left: unset;
        }

        &.TopEmojiPicker {
          :global(.raf-emoji-picker__container) {
            top: unset;
            bottom: 4rem;
          }
        }

        @include screens-above('md') {
          &.TopEmojiPicker {
            :global(.raf-emoji-picker__container) {
              top: unset;
              bottom: 4rem;
            }
          }
        }
      }

      & ~ .PostButton {
        position: absolute;
        right: 1.6rem;
        bottom: 1.6rem;
        display: block;
        width: 8rem;
        height: 4rem;
        font-size: 0.8em;
      }
    }
  }
}
