@import 'src/styles/mediaQueries';

:root {
  --toast-success-color: #51a351;
  --toast-success-background-color: #eeffe8;
  --toast-error-color: #ff5964;
  --toast-error-background-color: #ffeaed;
  --toast-warning-color: #f89406;
  --toast-warning-background-color: #ecdac0;
  --toast-info-color: #2f96b4;
  --toast-info-background-color: #8bbbca;
}

.success {
  background-color: var(--toast-success-background-color);
  color: var(--toast-success-color);

  svg {
    fill: var(--toast-success-color);
  }
}

.error {
  background-color: var(--toast-error-background-color);
  color: var(--toast-error-color);

  svg {
    fill: var(--toast-error-color);
  }
}

.info {
  background-color: var(--toast-info-background-color);
  color: var(--toast-info-color);

  svg {
    fill: var(--toast-info-color);
  }
}

.warning {
  background-color: var(--toast-warning-background-color);
  color: var(--toast-warning-color);

  svg {
    fill: var(--toast-warning-color);
  }
}

.ToastContainer {
  width: 100%;
  min-height: 4rem;
  padding: 0.8rem;
  margin: 3rem auto 0;

  @include screens-above('lg') {
    width: fit-content;
    padding: 0.8rem 1.5rem;
    margin: 0 auto;
  }
}

.ToastContent {
  overflow: hidden;
  margin: 0 auto;
  text-overflow: ellipsis;

  a {
    color: var(--color-white);
    text-decoration: underline;
  }
}
