.Wrapper {
  position: absolute;
  display: flex;
  width: calc(320px + 24px);
  height: calc(100vh - 20px);
  flex-direction: column;
  align-items: center;
  padding: 6px 24px 24px 24px;
  border: 1px solid var(--grey-300);
  box-shadow: var(--shadow);
}

.Header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey-300);
  margin-bottom: 5px;

  & .HeaderButton {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.EmptyWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  & p {
    margin: 5px;
  }
}
