@import 'src/styles/mediaQueries';

.Page {
  display: grid;
  padding: 1.5rem 1.5rem 5rem;
  grid-template-columns: 1fr auto 1fr;

  &.WithSideContent {
    grid-template-columns: 1fr auto 1fr auto;
  }

  .PageTitle {
    grid-column-start: 2;

    @include screens-below('md') {
      grid-column-start: 1;
      text-align: center;
    }
  }

  .MainContent {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;

    @include screens-below('md') {
      grid-column-start: 1;
    }
  }

  .SideContent {
    display: flex;
    flex-direction: column;
    grid-column-start: 4;
    grid-row-start: 1;

    &.SkipLine {
      grid-row-start: 2;
    }

    @include screens-below('md') {
      grid-column-start: 1;
    }
  }

  @include screens-below('md') {
    padding: 1rem 0.5rem 3rem;
    grid-template-columns: 1fr;

    &.WithSideContent {
      grid-template-columns: 1fr;
    }
  }
}
