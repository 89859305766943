@import 'src/styles/zIndex';

.ArrowContainer {
  position: absolute;
  z-index: z('base');
  top: calc(50% - 2rem);
  display: flex;
  width: 4rem;
  height: 4rem;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.LeftArrowContainer {
  left: 0.8rem;
}

.RightArrowContainer {
  right: 0.8rem;
}

.ArrowIcon {
  height: 1.4rem;
  margin: auto;
  fill: var(--color-white);
}

.ArrowIconLeft {
  transform: rotate(180deg);
}
