.Banner {
  border: 1px solid var(--color-gray);
  margin-bottom: 0.8rem;
  box-shadow: 0 0 1.7rem var(--color-shadow);
  font-size: 0;

  button {
    position: relative;
    left: 50%;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: none;
    transform: translate(-50%, -400%);
  }
}

.BigDesktopBanner {
  height: 477px;

  img {
    width: 288px;
    height: 477px;
  }

  button {
    bottom: 21px;
  }
}

.SmallDesktopBanner {
  height: 230px;

  img {
    width: 288px;
    height: 230px;
  }

  button {
    bottom: 12px;
  }
}

.Gradient {
  position: relative;
  width: 100%;
  height: 104px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 77.08%);
  transform: translateY(-100%);
}
