@import 'src/styles/mediaQueries';

.itemsGrid {
  display: flex;
  width: 100%;
  max-width: 87.6rem;
  flex-wrap: wrap;
  justify-content: center;
  order: 2;
  margin: 2.4rem auto 0;

  @include screens-above('lg') {
    justify-content: space-between;
    order: 3;
  }
}

.sectionTitle {
  justify-content: space-between;
  order: 1;
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 3.4rem;
  font-style: italic;
  font-weight: 800;
  line-height: 1;
  line-height: 4.4rem;
  text-align: center;
  text-align: left;
  text-transform: uppercase;

  @include screens-above('lg') {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
}

.titleLink {
  display: block;
  order: 3;
  padding-right: 2.7rem;
  margin: 1.8rem auto 0;
  background: url(../../assets/link_arrow.svg) right 75% no-repeat;
  background:
    url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='6' viewBox='0 0 14 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2929 5.29289L11 6L14 3L11 0L10.2929 0.707107L12.0858 2.50005L0 2.50005V3.50005L12.0857 3.50005L10.2929 5.29289Z' fill='%239A9D9E'/%3E%3C/svg%3E%0A")
    right/14px 6px no-repeat;
  background-size: 1.2rem 2.4rem;
  color: var(--color-text-title);
  color: var(--color-button-gray);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3rem / 1;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 900;
  font-weight: 700;
  line-height: 2rem;
  text-transform: initial;

  &:hover,
  &:focus {
    color: var(--color-brand-main);
  }

  @include screens-above('lg') {
    display: inline-block;
    order: 2;
    margin: 0;
  }
}

.Section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 2.4rem auto 3.4rem;

  @include screens-above('md') {
    max-width: 87.6rem;
    margin-bottom: 4.8rem;
  }
}
