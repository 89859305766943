@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Overlay {
  position: fixed;
  z-index: z('modal', 'lvl-2');
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0;
  background-color: rgba(var(--color-rgb-black), 0.8);
}

.Modal {
  position: relative;
  display: flex;
  overflow: scroll;
  width: 100%;
  min-height: calc(100vh - 4rem);
  flex-direction: column;
  padding: 1.6rem;
  margin: 0;
  background-color: var(--color-bg-2);
}

.Header {
  display: none;
}

.CloseButton svg path {
  fill: var(--color-white);
}

.CancelButton,
.SaveButton {
  flex: 1;
  margin: 0 0.4rem;
}

.GoBackButton {
  display: flex;
  width: 100%;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  border: none;
  background-color: var(--color-bg-1);
  font-weight: 700;
}

.GoBackButton span {
  margin-left: 0.8rem;
}

.GoBackButton:focus {
  border: none;
  outline: 1px solid var(--color-brand-main);
  outline-style: auto;
}

.Footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 0;
}

@include screens-above('lg') {
  .Overlay {
    padding: 1.4rem 0;
  }

  .Header {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 2.7rem;
  }

  .Modal {
    width: initial;
    min-width: 33%;
    max-width: 75%;
    min-height: fit-content;
    margin: auto;
  }

  .Content {
    width: 100%;
    padding: 0;
  }

  .SaveButton {
    width: 22.3rem;
  }

  .GoBackButton {
    display: none;
  }
}
