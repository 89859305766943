@import 'src/styles/mediaQueries';

.PostLikes,
.CommentIconWrapper {
  display: flex;
  height: 4rem;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--color-button-gray);
  cursor: pointer;
  line-height: normal;
}

.PostLikes.Active {
  border: 1px solid var(--color-brand-main);
  background-color: var(--color-brand-border);
  color: var(--color-brand-main);
}

.PostLikes .LikeIcon,
.PostLikes .CommentIcon {
  width: 1.8rem;
  fill: var(--color-button-gray);
  vertical-align: middle;
}

.PostLikes.Active .CommentIcon,
.PostLikes.Active .LikeIcon {
  fill: var(--color-brand-main);
}

.PostLikes span,
.CommentIconWrapper span {
  margin-left: 0.6rem;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
}

.ReactionIcons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.8rem;
}

.LoadMoreButton {
  @include screens-above('md') {
    max-width: 20rem;
  }
}

@include screens-above('md') {
  .CommentIconWrapper {
    margin-right: 0.8rem;
  }
}
