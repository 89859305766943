.BountyUnlockedContainer {
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.BorderTop {
  width: 100%;
  border-top: var(--color-border) 0.01rem solid;
}

.BountyUnlockedAvatar {
  display: inline-flex;
  width: 3.1rem;
  height: 3.1rem;
  align-items: center;
  justify-content: center;
}

.BountyUnlockedAvatar img {
  border: 0.01rem solid var(--color-white);
  border-radius: 50%;
}

.BountyUnlockedAvatar:nth-child(3) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.BountyUnlockedAdditionalUsers {
  margin-left: 0.8rem;
  color: var(--color-gray-background);
}

.BountyUnlockedAvatars {
  position: relative;
  display: flex;
}

.NobodyEarnedText {
  color: var(--color-text-gray);
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.04rem;
}
