@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.ProfileMenu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@include screens-above('md') {
  .Profile {
    min-width: 8.8rem;
    height: 4rem;
  }

  .ProfileChevron {
    width: 1.6rem;
    height: 1rem;
    margin: auto 1.3rem auto auto;
    background-image: url('../../../assets/chevron-down.svg');
  }

  .opened .ProfileChevron {
    transform: rotate(180deg);
  }
}

.Menu {
  position: fixed;
  top: 0;
  right: -75%;
  width: 75%;
  height: 100%;
  max-height: 100%;
  border-left: 1px solid var(--color-border);
  background: var(--color-bg-2);
  box-shadow: 0 0.2rem 1.4rem 0 rgba(var(--color-rgb-gray-blue), 0.25);
  overflow-y: auto;
  transition: 0.5s right;
}

.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--color-rgb-black), 0);
  transition: 0.5s background;
}

.opened .Overlay {
  background: rgba(var(--color-rgb-black), 0.47);
}

.hidden {
  right: -100%;
}

.opened .Menu {
  right: 0;
}

@include screens-above('md') {
  .opened .Overlay {
    background: rgba(var(--color-rgb-black), 0);
  }

  .opened .Menu {
    right: -1.5rem;
    display: block;
  }

  .Menu {
    position: absolute;
    z-index: z('profile-menu');
    top: 4.3rem;
    right: -1.5rem;
    display: none;
    width: 29.8rem;
    height: auto;

    /* set calc(viewport - header height) on the container to adjust scrollable content */
    max-height: calc(100vh - 7.3rem);
    border: 1px solid var(--color-border);
    border-radius: 0.2rem;
  }
}

.MenuSection {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
}

.MenuSection.Info {
  flex-direction: row;
  align-items: center;
  padding: 1.6rem;
}

.Text {
  margin-left: 1.7rem;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.Name {
  padding: 0.2rem 0;
  color: var(--color-text-title);
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
}

.Email {
  color: var(--color-text-gray);
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.4rem;
}

.Links {
  background-color: transparent;

  &:last-of-type {
    margin-bottom: 0.6rem;
  }
}

.Link {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.7rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.Link:hover {
  background: var(--color-bg-nav-active);

  > div {
    color: var(--color-text-nav-active);
  }
}

.LogoutIcon {
  width: 2rem;
}

.LinkText {
  margin-left: 1.2rem;
  color: var(--color-text-main);
  font-size: 1.4rem;
}

.Link *[stroke='#5A5665'],
.Link *[stroke='#CECCD1'] {
  stroke: var(--color-nav-icon);
}

.Link *[fill='#5A5665'] {
  fill: var(--color-nav-icon);
}

.Link:hover *[stroke='#5A5665'],
.Link:hover *[stroke='#CECCD1'],
.Link:hover *[stroke='#F63A55'] {
  stroke: var(--color-text-nav-active);
}

.Link:hover *[fill='#5A5665'],
.Link:hover *[fill='#CECCD1'],
.Link:hover *[fill='#F63A55'] {
  fill: var(--color-text-nav-active);
}
