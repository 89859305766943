.Wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 2px;
  background-color: var(--white);
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.AvatarWrapper {
  display: flex;
  height: 100%;
  align-items: flex-start;
  margin-right: 8px;
}

.ContentWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;

  & .ActionDescription {
    display: flex;
    flex-direction: row;

    & p {
      display: -webkit-box;
      overflow: hidden;
      max-width: 230px;
      margin: 0;
      -webkit-box-orient: vertical;
      color: var(--grey-600);
      font-size: 14px;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      white-space: normal;
    }

    & .Name {
      margin-right: 5px;
    }
  }

  & .Date {
    margin: 0;
    color: var(--grey-400);

    &.Unread {
      color: var(--red-60);
    }
  }
}

.UnreadWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.UnreadIndicator {
  width: 8px;
  height: 8px;
  background-color: var(--red-60);
  border-radius: 100%;
}

.ReadIndicator {
  composes: UnreadIndicator;
  visibility: hidden;
}
