@import 'src/styles/zIndex';

.Avatar {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: var(--red-60);
  border-radius: 50%;
}

.AvatarClickable {
  cursor: pointer;

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.AvatarInitials {
  margin: 0;
  border-radius: 50%;
  color: var(--white);
  text-transform: uppercase;
}

.AvatarImageSource {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
