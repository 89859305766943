@import 'src/styles/mediaQueries';

.FullWidthHeight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.WelcomePage {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.HalfLogin {
  width: 100%;
  margin-top: -2.7rem; /* complementing title margin */
  margin-top: 0;
  text-align: center;
}

.LoginWrapper {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
}

.Title {
  margin-top: 2.7rem;
  text-align: left;
}

.IntroText {
  margin-top: 2.7rem;
  color: var(--color-text-title);
  font: 1.4rem/1.5 'Source Sans', sans-serif;
  text-align: left;
}

.HalfSpotlight {
  display: flex;
  flex: 1;
  margin-top: 2.5rem;
  background-color: #0b1424;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@include screens-above('md') {
  .WelcomePage {
    flex-direction: row;
  }

  .Title {
    margin-top: 0;
  }

  .HalfLogin {
    display: flex;
    width: 50%;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .HalfSpotlight {
    width: 50%;
    flex: 1;
    padding: 0;
    margin-top: 0;
  }

  .IntroText {
    font-size: 1.7rem;
  }
}
