.toast-fade-enter {
  opacity: 0.01;
}

.toast-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.toast-fade-exit {
  opacity: 1;
}

.toast-fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms;
}

.ReactQueryDevtools > :not(.ReactQueryDevtoolsPanel) {
  right: 20px !important;
}

.raf-icon-badge__badge {
  top: 4px !important;
  min-width: 6px !important;
  height: 6px !important;

  p {
    display: none;
  }
}

div.react-select__control,
div.react-select__menu {
  border: 1px solid var(--color-border);
  background-color: var(--color-bg-2);
}

div.react-select__option,
div.react-select__option:active,
div.react-select__option:focus {
  background-color: var(--color-bg-2);
  color: var(--color-text-main);
}

div.react-select__option {
  border-bottom: 1px solid var(--color-border);
}

div.react-select__placeholder {
  color: var(--color-text-gray) !important;
}

div.react-select__single-value {
  color: var(--color-text-main);
}

div.react-select__multi-value {
  background-color: var(--color-tag-bg);
}

div.react-select__multi-value__label {
  color: var(--color-text-tag);
}

div.react-select__multi-value__remove {
  color: var(--color-text-tag);
}

.raf-emoji-picker {
  .emoji-mart {
    border: 1px solid var(--color-border);
    background: var(--color-bg-2);
    color: var(--color-text-main);

    .emoji-mart-search input {
      border: 1px solid var(--color-border);
      background: var(--color-bg-2);
      color: var(--color-text-main);
    }

    .emoji-mart-category-label span {
      background: var(--color-bg-2);
    }

    .emoji-mart-bar {
      border: 0;
    }

    .emoji-mart-anchor:hover svg,
    .emoji-mart-anchor-selected svg {
      cursor: pointer;
      fill: var(--color-brand-main);
    }

    .emoji-mart-anchor-bar {
      display: none;
    }
  }
}

ul.react-tabs__tab-list {
  padding: 0;
  margin: 0;
  list-style: none;

  > li.react-tabs__tab {
    display: inline-block;
    width: 33.33%;
    padding: 1rem 0;
    border-bottom: 0.1rem solid var(--color-disabled-text);
    color: var(--color-button-gray);
    cursor: pointer;
    font-family: var(--font-family-source-sans-pro);
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;

    &.react-tabs__tab--selected {
      border-bottom-color: var(--color-brand-main);
      color: var(--color-text-title);
    }
  }
}

div.react-tabs__tab-panel {
  margin-top: 4rem;
}

div.Collapsible {
  position: relative;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  border: 1px solid var(--color-light-gray);
  margin-bottom: 2rem;
  background: var(--color-bg-2);
}

span.Collapsible__trigger {
  position: relative;
  display: block;
  padding-left: 6rem;
  border-right: 1px solid var(--color-light-gray);
  margin-right: 6.5rem;
  color: var(--color-text-title);
  cursor: pointer;
  font-family: var(--font-family-prompt);
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 4rem;

  &::after {
    position: absolute;
    top: 1rem;
    right: -4rem;
    width: 1.5rem;
    height: 1.5rem;
    border-right: 0.2rem solid;
    border-bottom: 0.2rem solid;
    color: var(--color-button-gray);
    content: '';
    transform: rotate(45deg);
  }

  &.is-closed {
    margin-bottom: 2.4rem;

    &::after {
      top: 1.2rem;
      right: -4.5rem;
      transform: rotate(135deg);
    }
  }
}

div.Collapsible__contentInner {
  overflow-x: auto;
}
