@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.MediaDropPanel {
  position: relative;
  border: 1px solid transparent;
  margin: 0 auto 0.8rem;
  outline: 0;

  @include screens-above('md') {
    margin: 0 auto 1.6rem;
  }
}

.DropMask {
  position: absolute;
  z-index: z('popover');
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--color-brand-main);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.96) 100%);
}

.DropMask svg {
  width: 4rem;
  fill: var(--color-text-title);
}

.DropMask p {
  margin: 0;
  margin-top: 0.5rem;
  color: var(--color-text-title);
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
}

.MediaDropPanelInput {
  display: none;
}
