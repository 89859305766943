.Content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
}

.ActivitiesInfo {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}

.AvatarsContainer {
  padding-top: 0.5rem;
  width: 5rem;
}
