@import 'src/styles/zIndex';

.Wrapper {
  position: relative;
  z-index: z('dnd');
  width: auto;
  height: auto;

  * {
    pointer-events: none;
  }

  & .Content {
    display: none;
  }

  &.WrapperActive {
    border: 1px dashed var(--red-60);

    & .Content {
      position: absolute;
      z-index: z('dnd-content');
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px;
      background-color: var(--white);
      opacity: 0.85;

      & .Instructions {
        margin: 0;
        color: var(--grey-600);
        text-align: center;
      }

      & .Icon {
        display: initial;
      }

      & .InstructionsError {
        display: none;
      }

      & .IconError {
        display: none;
      }
    }
  }

  &.WrapperInactive {
    border: 1px dashed var(--red-60-primary);

    & .Content {
      position: absolute;
      z-index: z('dnd-content');
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px;
      background-color: var(--white);
      opacity: 0.85;

      & .Instructions {
        display: none;
      }

      & .Icon {
        display: none;
      }

      & .InstructionsError {
        display: initial;
        margin: 0;
        color: var(--red-80);
        text-align: center;
      }

      & .IconError {
        display: initial;

        & path {
          fill: var(--red-80);
        }
      }
    }
  }
}
