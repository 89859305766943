$space-units: (
  1: 0.4,
  2: 0.8,
  3: 1.2,
  4: 1.6,
  5: 2,
  6: 2.4,
  7: 2.8,
  8: 3.2,
  9: 3.6,
  10: 4,
  11: 4.4,
  12: 4.8,
  13: 5.2,
  14: 5.6,
  15: 6,
) !default;

$space-types: (
  m: 'margin',
  p: 'padding',
);

$space-directions: (
  l: 'left',
  r: 'right',
  t: 'top',
  b: 'bottom',
  y: (
    'top',
    'bottom',
  ),
  x: (
    'left',
    'right',
  ),
);

:root {
  --space-unit: 1rem;

  @each $unit, $scale-factor in $space-units {
    --space-#{$unit}: calc(#{$scale-factor} * var(--space-unit));
  }
}

/**
 * Generates following classes - similiar to Tailwind approach
 * m-0 - sets margin to 0
 * p-0 - sets padding to 0
 * mx-0 - sets left and right margin to 0
 * my-0 - sets top and bottom margin to 0
 * mt-1 - sets top margin to var(--space-1)
 * pl-2 - sets left padding to var(--space-2)
 * mb-3 - sets bottom margin to var(--space-3)
 * etc.
 */

@each $space-type-shortcut, $space-type-name in $space-types {
  .#{$space-type-shortcut}-0 {
    #{$space-type-name}: 0;
  }

  @each $space-direction-shortcut, $space-direction-names in $space-directions {
    .#{$space-type-shortcut}#{$space-direction-shortcut}-0 {
      @each $space-direction-name in $space-direction-names {
        #{$space-type-name}-#{$space-direction-name}: 0;
      }
    }

    @each $unit, $scale-factor in $space-units {
      .#{$space-type-shortcut}#{$space-direction-shortcut}-#{$unit} {
        @each $space-direction-name in $space-direction-names {
          #{$space-type-name}-#{$space-direction-name}: var(--space-#{$unit});
        }
      }
    }
  }
}
