@import 'src/styles/mediaQueries';

.Label {
  margin-bottom: 0.8rem;
  color: var(--color-text-gray);
  font: 400 1.5rem / 1.8 'Source Sans Pro', sans-serif;
}

@include screens-above('md') {
  .Label {
    min-width: 32.3rem;
    margin-bottom: 0;
  }
}

.Label_small {
  width: 50%;
}

.Label_small_fixed {
  width: 12.7rem;
  min-width: 12.7rem;
}

.Label_Tiny {
  width: 30%;
}

.Label_bold {
  color: var(--color-text-main);
  font: 900 1.4rem / 1.9 'Open Sans', sans-serif;
  text-transform: uppercase;
}

.Label_dark {
  color: var(--color-text-main);
}

.Label_select {
  line-height: 2.6rem;
}

.Label_bank_info {
  display: inline-block;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: var(--color-yellow-bg);
  color: var(--color-text-main);
}

.Label_profile_bank_info {
  display: inline-block;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 0;
  background-color: var(--color-yellow-bg);
  color: var(--color-text-main);
}

.Label_required::after {
  padding: 1rem;
  color: var(--color-red-bg);
  content: '*';
  font-size: 2.5rem;
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}

.Label > p:first-child {
  width: auto;
  margin: 1rem 2rem 1rem 2rem;
  font-weight: bold;
}

@include screens-above('md') {
  .Label_profile_unleash {
    line-height: 3.3rem;
  }
}

@include screens-above('md') {
  .Label_profile {
    width: 32.3rem;
    padding-right: 6rem;
  }
}
