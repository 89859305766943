@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

@keyframes fade-in-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.VideoContainer {
  position: relative;
  padding-top: 56.25%;
  margin: 2rem auto;
  background-color: var(--color-gray-input);
  line-height: 0;
}

.VideoEmbed {
  position: absolute;
  z-index: z('base');
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.VideoEmbed.PopupPlayer {
  position: fixed;
  top: unset;
  right: 20px;
  bottom: 20px;
  width: 260px;
  height: 145px;
  animation: fade-in-up 0.25s ease forwards;
  transform: translateY(100%);
}

.ClosePopupButton {
  display: none;
}

.ClosePopupButton.isPlaying {
  position: absolute;
  top: -2.5rem;
  right: 0;
  display: block;
  width: 24px;
}

.VideoEmbed > iframe {
  width: 100%;
  height: 100%;
}

@include screens-above('md') {
  .VideoContainer {
    width: 100%;
    max-width: 107.1rem;
    height: 61.6rem;
    padding: 0;
  }
}
