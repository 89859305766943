.CommonText {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;

  &.Contained {
    width: fit-content;
  }

  &.Fluid {
    width: auto;
  }

  &.Start {
    align-items: flex-start;
  }

  &.Center {
    align-items: center;
  }

  &.End {
    align-items: flex-end;
  }
}

.Title {
  composes: CommonText;
  margin: 8px 0;
}

.SubTitle {
  composes: CommonText;
  margin: 0;
}
