.CommonText {
  display: -webkit-box;
  overflow: hidden;
  max-width: calc(386px - 32px);
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
}

.Wrapper {
  width: 386px;
  height: 146px;
  cursor: pointer;

  &:hover {
    background: var(--grey-100);
  }

  &:active {
    border: 1px solid var(--grey-600);
  }
}

.Skills {
  display: flex;
  padding: 0;
  margin: 8px 0;
  list-style-type: none;

  & li:not(:last-child) {
    margin-right: 8px;
  }
}

.Skill {
  background-color: var(--grey-400);
}

.SkillText {
  color: var(--white);
}

.Title {
  composes: CommonText;
  -webkit-line-clamp: 1;
}

.Description {
  composes: CommonText;
  color: var(--grey-500);
  -webkit-line-clamp: 2;
}
