@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.PinnedPost {
  position: relative;
  min-width: 16rem;
  height: 20rem;
  border: 1px solid var(--color-gray);
  cursor: pointer;

  @include screens-above('md') {
    min-width: initial;
    height: 32rem;
    flex: 1;
  }
}

.PinnedPost:not(:nth-last-child(2)) {
  margin-right: 0.8rem;
}

.OverlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.OverlayMedia {
  background:
    linear-gradient(
      180deg,
      rgba(var(--color-rgb-black), 0.04) 0%,
      rgba(var(--color-rgb-black), 0.64) 100%
    );
  color: var(--color-white);
}

.TopRightContainer {
  position: absolute;
  right: 0;
  display: block;
  margin: 1.6rem;
}

.MenuIcon {
  z-index: z('base');
  width: 4rem;
  height: 4rem;
  padding: 0 45% 0 45%;
  border: 0;
  backdrop-filter: blur(4.8rem);
  background: rgba(var(--color-rgb-black), 0.24);
  cursor: pointer;
  outline: 0;
  text-align: center;
  vertical-align: middle;
}

div + .MenuIcon {
  background-color: var(--color-bg-1);
}

.MenuIcon svg {
  position: relative;
  width: 0.45rem;
  padding-bottom: 5%;
  margin: auto;
  fill: var(--color-white);
  vertical-align: middle;
}

.PostMenu {
  position: absolute;
  z-index: z('base');
  top: 4.2rem;
  right: 0;
}

.OverlayContent {
  position: absolute;
  bottom: 0;
}

.PostBody {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-height: 4rem;
  padding: 0 1.6rem;
  -webkit-box-orient: vertical;
  font: 700 1.4rem 'Source Sans Pro', sans-serif;
  letter-spacing: 0.02rem;
  -webkit-line-clamp: 2;
  line-height: 2rem;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.AuthorContainer {
  display: flex;
  align-items: center;
  padding: 1.6rem;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 2rem;
}

.AuthorContainer :global(.sb-avatar) {
  min-width: 3.2rem;
  margin-right: 0.8rem;
}

@include screens-above('lg') {
  .TopRightContainer {
    display: none;
  }

  .PinnedPost:hover {
    .TopRightContainer {
      display: initial;
    }
  }
}
