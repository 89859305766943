@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 16rem;
  max-height: 16rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
  background-color: var(--color-bg-1);
}

.Container.Pinned {
  width: 100%;
  height: 100%;
  max-height: unset;
}

.Container.Pinned .Media {
  height: 100%;
}

.OverIconContainer {
  position: absolute;
  z-index: z('base');
  top: 0.8rem;
  left: 0.8rem;
}

.OverIconContainer.Pinned {
  top: 0;
  left: 0;
  margin: 1.6rem;
}

.OverIconContainer > div {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  backdrop-filter: blur(4.8rem);
  background: rgba(var(--color-rgb-black), 0.24);
  text-align: center;
  vertical-align: middle;
}

.OverIconContainer svg {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  fill: var(--color-white);
  text-align: center;
  transform: translateY(-50%);
}

.Media {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  outline: 0;
}

@include screens-above('md') {
  .Container {
    width: 12.8rem;
    height: initial;
    margin-bottom: 0;
  }

  .ContainerWithMulti {
    display: flex;
    width: 100%;
    height: 35.7rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 0.8rem;
    margin-bottom: 0;
  }
}

.ContainerWithMulti {
  position: relative;
}
