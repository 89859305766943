@import 'src/styles/mediaQueries';

.Referrals {
  display: none;
  width: 100%;
  padding: 24px;
  border: var(--color-brand-main) solid 1px;
  background-color: var(--color-bg-2);
  box-shadow: 0 0 1.7rem var(--color-shadow);

  @include screens-above('lg') {
    display: block;
  }
}

.EventsTitle {
  position: relative;
  padding-bottom: 1.6rem;
  margin: 0;
  margin-bottom: 1.6rem;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.EventsTitle::after {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-gray);
  content: '';
}

.ReferralsText {
  margin-bottom: 1.6rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
}

.ActionButton {
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: none;
}

.ReferralsDropdown {
  font-size: 14px;
  line-height: 20px;

  & span {
    display: none;
  }

  svg {
    fill: var(--color-text-main);
  }
}

.JobOpeningsButton {
  border: none;
  color: var(--color-button-gray);
}
