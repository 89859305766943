@use 'sass:math';

@import 'src/styles/mediaQueries';

.grid {
  width: 100%;
  box-sizing: border-box;

  --gutter-width: 12px;

  @include screens-above('md') {
    --gutter-width: 24px;
  }
}

.row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
}

.column {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

@mixin addColumnDefinition($i, $breakpoint) {
  $columns: 12;
  $widthUnit: math.div(100%, $columns);
  $gutterUnit: math.div(($columns - 1), $columns);

  .#{$breakpoint}--#{$i} {
    width: calc(#{$i * $widthUnit} + #{$i * (1 - $gutterUnit) - 1} * var(--gutter-width));
    margin-right: var(--gutter-width);

    &:last-child {
      margin-right: 0;
    }
  }
  .push-#{$breakpoint}--#{$i} {
    margin-left: calc(#{$i * $widthUnit} + #{$i * (1 - $gutterUnit)} * var(--gutter-width));
  }
}

@for $i from 1 through 12 {
  @for $breakpointIndex from 1 through length($breakpoints) {
    $breakpoint: nth(nth($breakpoints, $breakpointIndex), 1);

    @if $breakpointIndex == 1 {
      // first breakpoint should go without any media queries
      @include addColumnDefinition($i, $breakpoint);
    }

    @else {
      // 'md' goes inside "screens-aboce('xs')" etc.
      $breakpointName: nth(nth($breakpoints, $breakpointIndex - 1), 1);

      @include screens-above($breakpoint) {
        @include addColumnDefinition($i, $breakpointName);
      }
    }
  }
}

.debug {
  position: relative;
  min-height: 50px;
  border: 1px dotted red;
  background-color: rgba(red, 0.1);

  .debug {
    border-color: green;
    background-color: rgba(green, 0.1);

    .debug {
      border-color: blue;
      background-color: rgba(blue, 0.1);

      .debug {
        border-color: turquoise;
        background-color: rgba(turquoise, 0.1);

        .debug {
          border-color: burlywood;
          background-color: rgba(burlywood, 0.1);
        }
      }
    }
  }

  &.column {
    &::before {
      position: absolute;
      left: calc(-1 * var(--gutter-width) - 1px);
      width: var(--gutter-width);
      height: 50px;
      background: rgba(red, 0.5);
      content: '';
    }

    &:first-child::before {
      display: none;
    }
  }
}
