@import 'src/styles/zIndex';

.CommonAvatarGroup {
  display: inline-flex;
  flex-direction: row;
}

.AvatarGroupGroup {
  composes: CommonAvatarGroup;

  & div {
    position: relative;
    overflow: hidden;
  }

  & div:not(:first-child) {
    margin-left: -0.625rem;
  }
}

.AvatarGroupIndividual {
  composes: CommonAvatarGroup;
}

.AvatarItemGroup {
  z-index: z('image');
}

.AvatarItemIndividual {
  z-index: z('image');
  margin-right: 0.188rem;
}

.AvatarGroupClickable {
  cursor: pointer;
}
