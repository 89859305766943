.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    margin: 4rem 0;
  }

  strong,
  span {
    color: var(--color-text-title);
    font-size: 1.7rem;
    line-height: 2.6rem;
  }
}
