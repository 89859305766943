@import 'src/styles/mediaQueries';

.Logo {
  width: 3rem;
  height: 3rem;

  @include screens-below('md') {
    position: absolute;
    top: 18px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.Logo path {
  fill: var(--color-text-title);
}
