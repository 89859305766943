.CommonText {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CommonTextBold {
  composes: CommonText;
  font-weight: bold;
}

.CommonUnleashIcon {
  width: 70px;
  height: 70px;
}

.CommonUnleashTitle {
  composes: CommonText;
  max-width: calc(304px - 120px);
  margin: 0;
  color: var(--black);
}

.CommonUnleashSubTitle {
  composes: CommonText;
  max-width: calc(304px - 120px);
  margin: 0;
  color: var(--grey-400);
}

.Unleash {
  box-sizing: border-box;
  padding: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1.7rem rgba(black, 0.15);
  }

  &:hover .CommonUnleashTitle {
    color: var(--red-60);
  }

  &:active {
    outline: 1px dashed var(--red-60);
  }

  & * {
    box-sizing: border-box;
  }
}

// HORIZONTAL

.UnleashHorizontal {
  composes: Unleash;
  max-width: 304px;
}

.UnleashHeaderHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.UnleashIconHorizontal {
  composes: CommonUnleashIcon;
}

.UnleashHeaderHorizontalInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
}

.UnleashTitleHorizontal {
  composes: CommonUnleashTitle;
}

.UnleashSubTitleHorizontal {
  composes: CommonUnleashSubTitle;
}

.UnleashDescriptionHorizontal {
  display: -webkit-box;
  overflow: hidden;
  margin: 0;
  -webkit-box-orient: vertical;
  color: var(--grey-500);
  -webkit-line-clamp: 4;
  text-align: left;
}

// VERTICAL

.UnleashVertical {
  composes: Unleash;
  max-width: 222px;
}

.UnleashHeaderVertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.UnleashIconVertical {
  composes: CommonUnleashIcon;
  margin-bottom: 10px;
}

.UnleashTitleVertical {
  composes: CommonUnleashTitle;
}

.UnleashSubTitleVertical {
  composes: CommonUnleashSubTitle;
}

.UnleashDescriptionVertical {
  margin: 0;
  color: var(--grey-500);
  text-align: center;
}
