@import 'src/styles/mediaQueries';

.HomeWrapper {
  display: flex;
  width: 100%;
}

.InnerWrapper {
  margin-bottom: 1.7rem;

  @include screens-above('md') {
    margin-bottom: 2.8rem;
  }
}

.Card {
  max-width: 75%;
  height: 100%;
  margin-right: 1.2rem;
  white-space: normal;

  &:last-of-type {
    margin-right: 0;
  }

  @include screens-above('md') {
    max-width: 100%;
  }
}
