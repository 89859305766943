@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Toast {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid;
  border-radius: 0;
}

.Toast:not(:last-child) {
  margin: 0 auto 0.8rem;
}

.Toasts {
  position: fixed;
  z-index: z('toast');
  top: 2.4rem;
  left: 53%;
  margin-top: 3rem;
  transform: translate(-50%, 0);

  @include screens-above('lg') {
    margin-top: 0;
  }
}
