@import 'src/styles/mediaQueries';

.Value {
  font: 400 'Source Sans Pro', sans-serif;
  font-size: 1.5rem / 1.8;
}

.Value_note {
  font-style: oblique;
  opacity: 0.7;
}

.Value_payment {
  font-style: oblique;
  opacity: 0.7;
}

.Value_paymentDisplay {
  padding: 0;
  color: var(--color-text-gray);
  font: 400 1.6rem/100% 'Source Sans Pro', sans-serif;
}

@include screens-above('md') {
  .Value_paymentDisplay {
    flex: 1;
    padding: 0;
    color: var(--color-text-main);
    font: 400 1.4rem / 1.9 'Source Sans Pro', sans-serif;
  }
}
