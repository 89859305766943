@import 'src/styles/mediaQueries';

.Input {
  width: 19.6rem;
  padding: 2.1rem 1.7rem;
  border: 1px solid var(--color-border);
  margin-right: 1.2rem;
  margin-bottom: 1.7rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-bg-2);
  border-radius: 0;
  color: var(--color-text-main);
  font-size: 1.6rem;
}

@include screens-above('sm') {
  .Input {
    margin-right: 2.5rem;
  }
}

.Input_reimburseNumber {
  width: 100%;
  height: 5.4rem;
  color: var(--color-text-main);
  font: 400 1.4rem / 1.6 'Source Sans Pro', sans-serif;
}

.Input:last-child {
  margin-right: 0;
}

.Input:focus {
  border: 1px solid var(--color-brand-main);
  box-shadow: 0 0 1px var(--color-text-title);
  outline: none !important;
}

.Input:disabled {
  background-color: var(--color-bg-1);
  color: var(--color-text-gray);
}

.Input,
.Input_rate {
  font-size: 1.6rem;
}

.Input_rate {
  display: block;
  padding: 2.1rem 0;
  border: none;
  margin-right: 2.5rem;
  margin-bottom: 1.7rem;
  color: var(--color-text-main);
}

.Input_payment {
  height: 5.4rem;
}

.Input_reimb {
  width: 100%;
  padding: 1.7rem;
  margin-bottom: 0;
}

.Input_search {
  padding: 1.15rem 2rem;
  margin-bottom: 0;
}

.Input_small {
  width: 20%;
}

.Input_fullwidth {
  width: 100%;
}

.Input[name*='name']:disabled {
  width: 100%;
}

.Input_modal {
  width: 29.8rem;
  max-width: 100%;
  padding: 0 0.8rem;
  margin: 0;
  color: var(--color-text-main);
  font: 100 1.7rem / 2.4 'Source Sans Pro', sans-serif;
}

@include screens-above('md') {
  .Input_modal {
    max-height: 5.1rem;
  }
}

.Input_narrow {
  width: 8.5rem;
}

.Input_error {
  padding: 0;
  border: none;
  margin-bottom: 0.7rem;
  color: var(--color-red);
  font-size: 1.1rem;
}

@include screens-above('md') {
  .Input_reimburseNumber {
    width: 12.3rem;
    height: 5.4rem;
  }
}

@include screens-above('lg') {
  .Input_rate {
    font-size: 1.4rem;
  }
}
