.Events {
  display: block;
  width: 100%;
  padding: 3.4rem 2.5rem 2.5rem;
  border: 1px solid var(--color-gray);
  margin: 0 auto;
  background-color: var(--color-bg-2);
  box-shadow: 0 0 1.7rem var(--color-shadow);
}

.EventsTitle {
  position: relative;
  padding-bottom: 1.6rem;
  margin: 0;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.EventsTitle::after {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-shadow);
  content: '';
}

.EventsList {
  padding: 0;
  margin: 0;
}

.Event {
  position: relative;
  padding: 1.6rem 0.8rem 1.6rem 0;
  border-bottom: 1px solid var(--color-shadow);
  margin: 0.8rem 0 0;
  list-style: none;
}

.Event_link {
  display: block;
  padding-right: 1.7rem;
  background: url(../../../assets/link_arrow.svg) right calc(50% - 0.3rem) no-repeat;
  background-size: 1.2rem 1.5rem;
}

.Event_title {
  display: block;
  margin: 0 0 0.8rem;
  color: var(--color-text-title);
  font: 700 1.4rem / 1.4 'Source Sans Pro', sans-serif;
}

.Event_date {
  color: var(--color-text-main);
  font: 1.4rem / 1.7 'Source Sans Pro', sans-serif;
}
