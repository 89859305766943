.CommonLabelLayout {
  width: fit-content;
  padding: 0 4px;

  &.LabelClickable:focus {
    outline: 1px dashed var(--red-60);
  }
}

.LabelText {
  margin: 0;
  color: var(--white);
}

.LabelDarkText {
  composes: LabelText;
  color: var(--black);
}

.LabelClickable {
  cursor: pointer;
}

.LabelNoBackground {
  composes: CommonLabelLayout;
  background-color: transparent !important;
}

.LabelCommon {
  composes: CommonLabelLayout;
  background-color: var(--grey-400);
}

.LabelBlack {
  composes: CommonLabelLayout;
  background-color: var(--black);
}

.LabelPrimary {
  composes: CommonLabelLayout;
  background-color: var(--red-60);
}
