@import 'src/styles/zIndex';

.Card {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid var(--grey-300);
  background: var(--white);

  &.Highlighted {
    border: 1px solid var(--red-40);
  }
}
