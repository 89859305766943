.DocumentWrapper {
  padding-bottom: 0.5rem;
}

.DocumentName {
  margin-top: 4rem;
}

.DocumentLink {
  color: var(--color-text-main);
  text-decoration: underline;
  display: block;
}

.TermCheckbox {
  display: flex;
  padding-top: 1rem;
}

.TermText {
  color: var(--color-brand-main);
  line-height: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
}
