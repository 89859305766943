@import 'src/styles/zIndex';

.LottieLoadingAnimation {
  position: relative;
  top: 50%;
  left: 0%;
}

.LoadingContainer {
  position: absolute;
  z-index: z('modal', 'lvl-1');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
}

.LottieInlineLoadingAnimation {
  position: relative;
  top: 5%;
  left: 0%;
}

.InlineLoadingContainer {
  position: relative;
  z-index: z('modal', 'lvl-1');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
}
