@import 'src/styles/zIndex';

.Toast {
  position: fixed;
  z-index: z('toast');
  width: 375px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  transition-delay: 99000ms;
  transition-duration: 1000ms;

  * {
    box-sizing: border-box;
  }

  &.TopCenter {
    top: 20px;
    left: 50%;
    margin-left: -192px;
  }

  &.BottomCenter {
    bottom: 20px;
    left: 50%;
    margin-left: -192px;
  }
}
