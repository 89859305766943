@import 'src/styles/mediaQueries';

.Label {
  display: block;
  width: 19.6rem;
  padding: 0 1.7rem;
  margin-right: 2.5rem;
  margin-bottom: 2.1rem;
  color: var(--color-text-main);
  line-height: 5.1rem;
}

.Label_multiline {
  line-height: 2.5rem;
}

.Label_halfwidth {
  width: 50%;
  margin-right: 0.8rem;
}

.Label_noPadding {
  padding: 0;
}

.Label_noMargin {
  margin-bottom: 0;
}

.Label_unleashBudget,
.Label_reimburseLabel,
.Label_reimburseField,
.Label_submitStep {
  padding: 0;
  margin: 0 0 0.3rem 0;
  font: 400 1.7rem / 1.8 'Source Sans Pro', sans-serif;
}

.Label_paymentDisplay {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.5;
  opacity: 0.4;
}

.Label_submitStep {
  font-size: 1.4rem;
}

.Label_reimburseField {
  margin: 1.7rem 0 0.3rem 0;
  font-size: 1.4rem;
}

.Label_reimburseLabel {
  display: none;
}

.Label_manualDeduction,
.Label_manualBonus {
  width: 100%;
  padding: 1rem 3rem;
  margin: 0;
  line-height: 1;
  text-align: center;
}

.Label_manualDeduction {
  background: var(--color-yellow-bg);
}

.Label_manualBonus {
  background: var(--color-green-bg);
}

.Label_unleashBudget {
  width: 100%;
  margin-top: 1.7rem;
  font-size: 1.4rem;
  font-weight: 700;
}

.Label_unleashBudget span {
  font-size: 1.4rem;
  font-weight: 400;
}

.Label_required::after {
  padding: 0.4rem;
  color: var(--color-red-bg);
  content: '*';
}

@include screens-above('md') {
  .Label_paymentDisplay {
    flex: 1;
    color: var(--color-text-gray);
  }

  .Label_submitStep {
    width: 100%;
    font-size: 1.7rem;
  }

  .Label_unleashBudget {
    display: block;
    width: 100%;
    min-width: 31.5rem;
    margin-top: 5.1rem;
    margin-bottom: 4.3rem;
    font: 700 2.2rem / 1.4 'Source Sans Pro', sans-serif;
  }

  .Label_unleashBudget span {
    font-size: 2.2rem;
    font-weight: 400;
  }

  .Label_reimburseField {
    display: none;
  }

  .Label_reimburseLabel {
    display: flex;
  }
}

.Label_payment {
  width: 100%;
  padding: 0;
  margin: 0 0 1rem;
  font-weight: bold;
  line-height: 1.5;
  opacity: 0.4;
}

.Label_modal {
  padding-left: 0;
  margin: 0.8rem 0;
  line-height: 1;
}

@include screens-above('md') {
  .Label_modal {
    padding: 0 1.7rem;
    margin: 0 2.5rem 0 0;
    line-height: 5.1rem;
  }
}
