@import 'src/styles/mediaQueries';

.ListWrapper {
  border-top: 1px solid var(--color-border);
}

.ListSection {
  margin: 5rem auto;
}

.Title {
  margin: 0;
  color: var(--color-text-main);
  font: 400 2rem / 1.8 'Open Sans', sans-serif;
  letter-spacing: 0;
  text-align: left;
}

@include screens-above('md') {
  .ConfirmButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ConfirmButton {
    max-width: 40rem;
  }
}
