@import 'src/styles/mediaQueries';

.FeedModal {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem;

  @include screens-above('md') {
    width: 69.6rem;
    padding: 4rem;
  }
}

.Title {
  margin-bottom: 1.6rem;
  color: var(--color-text-main);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.5rem;

  @include screens-above('md') {
    font-size: 3rem;
    line-height: 3.2rem;
  }
}

.ContentText {
  color: var(--color-text-main);
  font-size: 1.7rem;
  line-height: 2.6rem;
}

.ContentWarning {
  margin-top: 4rem;
  font-weight: 700;
}

.ButtonGroup {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 4rem;
}

.ActionButton {
  flex: 1;
  padding: 1rem 2.4rem;
  margin-left: 0.8rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: none;

  @include screens-above('md') {
    flex: initial;
  }
}

.ActionButton:first-of-type {
  margin-left: 0;
}
