@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Wrap {
  position: relative;
  width: 100%;
  max-width: 97.25rem;
  margin: 0 auto;
}

.Wrap_wide {
  max-width: 107.9rem;
}

.Wrap_table {
  min-height: 45vh;
  padding-bottom: 20rem;
  overflow-x: auto;
}

.Wrap_shadow_left::before {
  position: absolute;
  z-index: z('base');
  top: 0;
  left: 0;
  width: 15px;
  height: 100%;
  background:
    linear-gradient(
      90deg,
      rgba(var(--color-rgb-black), 0.25),
      rgba(var(--color-rgb-black), 0)
    );
  background-size: 15px 100%;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  content: '';
}

.Wrap_shadow_right::after {
  position: absolute;
  z-index: z('modal', 'lvl-1');
  top: 0;
  right: 0;
  width: 15px;
  height: 100%;
  background:
    linear-gradient(
      270deg,
      rgba(var(--color-rgb-black), 0.25),
      rgba(var(--color-rgb-black), 0)
    );
  background-size: 15px 100%;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  content: '';
}

@include screens-above('md') {
  .Wrap {
    width: 90%;
  }

  .Wrap_shadow_left::before {
    background:
      linear-gradient(
        90deg,
        rgba(var(--color-rgb-black), 0.1),
        rgba(var(--color-rgb-black), 0)
      );
  }

  .Wrap_shadow_right::after {
    background:
      linear-gradient(
        270deg,
        rgba(var(--color-rgb-black), 0.1),
        rgba(var(--color-rgb-black), 0)
      );
  }
}

.Wrap_flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Wrap_flex_centered {
  align-items: center;
}

.Wrap_aligned {
  align-items: center;
}

.Wrap_fullwidth {
  width: 100%;
  max-width: 100%;
}

.Wrap_with_bottom_button {
  margin-bottom: 8rem;
}

.Wrap_mobile {
  height: 0;
}

@include screens-above('lg') {
  .Wrap_flex {
    flex-direction: initial;
  }
}
