@import 'src/styles/zIndex';

.TooltipParent {
  position: relative;
  width: auto;
  height: auto;

  &:hover .Tooltip {
    display: initial;
  }

  & .Tooltip {
    position: absolute;
    display: none;
    padding: 6px 12px;
    background-color: var(--black);

    &.PlacementBottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      &::after {
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-right: 8px solid transparent;
        border-bottom: 8px solid var(--black);
        border-left: 8px solid transparent;
        margin-left: -8px;
        content: '';
      }
    }

    &.PlacementTop {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);

      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: 8px solid var(--black);
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        margin-left: -8px;
        content: '';
      }
    }

    &.PlacementRight {
      left: 100%;

      &::after {
        position: absolute;
        top: 50%;
        right: 100%;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 8px solid var(--black);
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
        margin-top: -8px;
        content: '';
      }
    }

    &.PlacementLeft {
      right: 100%;

      &::after {
        position: absolute;
        top: 50%;
        left: 100%;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid var(--black);
        margin-top: -8px;
        content: '';
      }
    }

    &:hover {
      display: initial;
    }

    & .TooltipText {
      margin: 0;
      color: var(--white);
    }
  }
}
