@import 'src/styles/zIndex';

.CommonAbsolutePositioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CommonText {
  overflow: hidden;
  max-width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BannerWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 88px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;

  & * {
    box-sizing: border-box;
  }
}

.BannerImage {
  composes: CommonAbsolutePositioning;
  z-index: z('base');
  object-fit: cover;
  object-position: 50% 50%;
}

.BannerPlaceholder {
  composes: CommonAbsolutePositioning;
  z-index: z('base');
  border: 1px solid var(--grey-300);
  background-color: var(--black);
}

.BannerContent {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.BannerTitle {
  composes: CommonText;
  flex-grow: 30;
  color: var(--white);
}

.BannerButton {
  flex-grow: 1;
}
