.button {
  min-width: 8.4rem;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.4rem;
  border: 1px solid transparent;
  margin: 0;
  appearance: none;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 2rem;
  text-align: left;
  transition: background 0.2s, color 0.2s, border-color 0.2s;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.layout--autolayout {
  display: inline-flex;
  width: auto;
  max-width: 100%;
}

.layout--fluid {
  display: flex;
  width: 100%;
}

.variant--primary {
  background: var(--red-60);
  color: var(--white);

  & svg {
    & path {
      fill: var(--white);
    }
  }

  &:hover {
    background: var(--red-80);
  }

  &:disabled {
    background: var(--grey-200);
    color: var(--grey-300);
  }
}

.variant--secondary {
  border-color: var(--grey-300);
  background: var(--white);
  color: var(--grey-400);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover {
    border-color: var(--red-60);
    color: var(--red-60);
  }

  &:disabled {
    border-color: var(--grey-300);
    color: var(--grey-300);
  }
}

.variant--tertiary {
  background: var(--white);
  color: var(--grey-400);

  & svg {
    & path {
      fill: var(--grey-400);
    }
  }

  &:hover {
    color: var(--red-60);
  }

  &:disabled {
    color: var(--grey-300);
  }
}

.icon {
  height: 100%;
}

.icon-position--start {
  flex-direction: row;

  .icon {
    margin: 0 0.6rem 0 0;
  }
}

.icon-position--end {
  flex-direction: row-reverse;

  .icon {
    margin: 0 0 0 0.6rem;
  }
}
