@import 'src/styles/zIndex';

.CommonAbsolutePositioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CommonTrimText {
  overflow: hidden;
  max-width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CommonVaultText {
  composes: CommonTrimText;
  margin: 0;
  margin-left: 1rem;
  color: white;
}

.VaultWrapper {
  position: relative;
  width: 222px;
  height: 320px;
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    outline: 1px dashed var(--red-60);
  }

  & * {
    box-sizing: border-box;
  }
}

.VaultImage {
  composes: CommonAbsolutePositioning;
  z-index: z('base');
  background: linear-gradient(9deg, rgba(84, 84, 84, 1) 0%, rgba(255, 255, 255, 0) 100%);
  object-fit: cover;
  object-position: 50% 50%;
}

.VaultPlaceholder {
  composes: CommonAbsolutePositioning;
  composes: VaultImage;
  border: 1px solid black;
}

.VaultImageOverlay {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  background: linear-gradient(9deg, rgba(84, 84, 84, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.VaultContent {
  composes: CommonAbsolutePositioning;
  z-index: z('image-content');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}

.VaultHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.VaultHeaderBadge {
  display: flex;
  width: 60px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  backdrop-filter: blur(48px);
  background: rgba(0, 0, 0, 0.24);
}

.VaultHeaderBadgeIcon {
  width: 16px;
  height: 16px;
}

.VaultFooter {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.VaultFooterTitleArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.VaultFooterTitleIcon {
  width: 20px;
  height: 23px;
}

.VaultFooterProgressBarText {
  color: var(--white);
}

.VaultFooterTitle {
  composes: CommonVaultText;
  text-transform: uppercase;
}
