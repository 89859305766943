@import 'src/styles/zIndex';

.FeedPostMenu {
  display: flex;
  width: 20rem;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-bg-2);
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.12);
}

.FeedPostMenu button,
.FeedPostMenuMobile button {
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  line-height: 100%;
  text-align: left;
}

.FeedPostMenu button:hover {
  background-color: var(--color-bg-3);
}

.DefaultButton {
  color: var(--color-text-main);
}

.DefaultButton svg {
  fill: var(--color-text-main);
}

.RemoveButton {
  color: var(--color-brand-main);
}

.RemoveButton svg {
  fill: var(--color-brand-main);
}

.FeedPostMenu svg,
.FeedPostMenuMobile svg {
  width: 1.6rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.FeedPostMenuMobile {
  position: fixed;
  z-index: z('modal', 'lvl-1');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: auto;

  button {
    font-size: 1.4rem;
    letter-spacing: 0.0001rem;
  }
}

.FeedPostMenuMobileMenu {
  position: fixed;
  z-index: z('base');
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.6rem;
  background-color: var(--color-bg-2);
  border-radius: 2.4rem 2.4rem 0 0;
}

.ActionButtonMobile {
  width: 100%;
  height: 4.8rem;
}
