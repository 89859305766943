@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.Root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: var(--color-bg-2);
}

.CommunityBackground {
  padding-top: 0;
  background-color: var(--color-bg-1);

  @include screens-above('lg') {
    padding-top: 8rem;
  }
}
