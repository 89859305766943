@import 'src/styles/mediaQueries';

.PageContents {
  max-width: 100%;
  padding: 0 1.7rem;
  margin: 7rem 0 0 6rem;
}

@include screens-above('lg') {
  .PageContents {
    padding: 0;
    margin-top: 5.5rem;
  }
}
