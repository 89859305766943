.Wrapper {
  display: flex;
  width: 767px;
  height: 80px;
  flex-direction: row;
  padding: 24px;
  background-color: var(--white);
  box-shadow: 0 1px 0 var(--grey-300);
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.WrapperMobile {
  width: 767px;
  height: 96px;
  padding: 16px;
}

.Avatar {
  display: flex;
  justify-content: center;
  margin-right: 8px;
}

.Content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .Category {
    margin: 0;
    color: var(--grey-400);
  }

  &.ContentMobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & .Category {
      width: 100%;
    }
  }

  & .Description {
    display: -webkit-box;
    overflow: hidden;
    max-width: 500px;
    flex-grow: 1;
    margin: 0;
    -webkit-box-orient: vertical;
    font-size: 14px;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;

    & .Name {
      margin-right: 5px;
      font-weight: bold;
    }

    & .Item {
      margin-left: 5px;
      color: var(--red-60);
    }
  }
}
