@import 'src/styles/zIndex';

.SnackbarAlertContainer {
  width: 100%;
  padding: 0 1rem;
}

.SnackbarAlertContainerHidden {
  visibility: hidden;
}

.SnackbarAlertBody {
  position: fixed;
  z-index: z('toast');
  top: 8rem;
  right: 0;
  left: 0;
  max-width: 35rem;
  height: 4rem;
  margin: auto;
  cursor: pointer;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.SnackbarAlertContent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.SnackbarAlertSuccess {
  border: 1px solid var(--color-green2-bg);
  background-color: var(--color-light-green-bg);
  box-shadow: 0 8px 24px rgba(var(--color-rgb-green2-bg), 0.16);
  color: var(--color-green2-bg);
}

.SnackbarAlertError {
  border: 1px solid var(--color-brand-main);
  background-color: var(--color-brand-border);
  color: var(--color-brand-main);
}
