@import 'src/styles/mediaQueries';

.Card {
  min-width: 288px;
  align-items: center;

  p {
    margin: 0;
    color: var(--grey-600);
  }

  .mainContent {
    display: flex;
    min-width: 236px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .avatar {
      width: 80px;
      height: 80px;
    }

    > div {
      text-align: center;
    }

    .role {
      color: var(--grey-400);
      text-transform: capitalize;
    }
  }

  @include screens-below('md') {
    width: 100%;
    align-items: flex-start;

    .mainContent {
      flex-direction: row;

      .avatar {
        width: 40px;
        height: 40px;
      }

      > div {
        text-align: start;
      }
    }
  }

  @include screens_above('sm') {
    .divider {
      max-width: 174px;
    }
  }
}
