.Wrapper {
  display: flex;
  width: 48px;
  height: 76px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &.Clickable {
    cursor: pointer;

    &:active {
      outline: 1px dashed var(--red-60);
    }
  }
}

.Span {
  display: flex;
  max-width: 48px;
  max-height: 48px;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 100%;

  &.SpanFilled {
    border: 2px solid var(--red-60);
    background-color: var(--red-60);
  }

  &.SpanActive {
    border: 2px solid var(--red-60);
    background-color: var(--transparent);
  }

  &.SpanInactive {
    border: 2px solid var(--grey-300);
    background-color: var(--transparent);
  }
}

.NumberStep {
  margin: 0;

  &.NumberStepFilled {
    color: var(--white);
  }

  &.NumberStepActive {
    color: var(--black);
  }

  &.NumberStepInactive {
    color: var(--grey-400);
  }
}

.Label {
  margin: 0;

  &.LabelFilled {
    color: var(--black);
  }

  &.LabelActive {
    color: var(--red-60);
  }

  &.LabelInactive {
    color: var(--grey-400);
  }
}
