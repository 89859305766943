@import 'src/styles/mediaQueries';

.VaultItems {
  composes: Section from '../Home.module.scss';
}

.vaultItemsGrid {
  composes: itemsGrid from '../Home.module.scss';
}

.sectionTitle {
  composes: sectionTitle from '../Home.module.scss';
}

.titleLink {
  composes: titleLink from '../Home.module.scss';
}

a.VaultItemLink {
  display: flex;
  width: 100%;
  max-width: 34rem;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: 0.8rem;
  color: var(--color-text-main);

  @include screens-above('md') {
    width: calc(33% - 1.6rem);
    margin-top: 0;
    margin-right: 1.6rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  img {
    max-width: none;
  }
}
