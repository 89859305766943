.LoginWrapper {
  display: inline-block;
}

.KeepMeSignedIn {
  display: flex;
  flex-direction: row;
  margin-top: 2.1rem;
}

.KeepMeSignedInCheckbox {
  margin-left: 1.7rem;
}
