@import 'src/styles/zIndex';

.CommonAbsolutePositioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CommonText {
  overflow: hidden;
  max-width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CardWrapper {
  position: relative;
  width: 100%;
  min-width: 217px;
  height: 100%;
  min-height: 320px;
  cursor: pointer;

  &:focus {
    outline: 1px dashed var(--red-60);
  }

  &:hover .CardActions {
    visibility: visible;
  }
}

.CardImagePlaceholderDark {
  composes: CommonAbsolutePositioning;
  z-index: z('base');
  background-color: var(--black);
}

.CardImagePlaceholderLight {
  composes: CommonAbsolutePositioning;
  composes: CardImagePlaceholderDark;
  background-color: var(--white);
}

.CardOverlay {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  background: linear-gradient(9deg, rgba(84, 84, 84, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.CardOverlayBorderOnlyLight {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  border: 1px solid var(--grey-300);
}

.CardOverlayBorderOnlyDark {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  border: 1px solid var(--black);
}

.CardContentWrapper {
  composes: CommonAbsolutePositioning;
  z-index: z('image-content');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  color: var(--black);
}

.CardContentWrapperWithoutAction {
  composes: CommonAbsolutePositioning;
  composes: CardContentWrapper;
  justify-content: flex-end;
}

.CardActions {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  color: var(--black);
  visibility: hidden;
}

.CardButton {
  width: 40px;
  height: 40px;
  border: 0;
  background: rgba(0, 0, 0, 0.24);
  color: var(--white);
  cursor: pointer;
  outline: 0;

  &:focus {
    outline: 1px dashed var(--red-60);
  }

  & > svg {
    width: 14px;
    height: 14px;
    fill: var(--white);
  }
}

.CardFooter {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.CardTitle {
  composes: CommonText;
  margin: 0;
  color: var(--white);
}

.CardTitleLight {
  composes: CardTitle;
  color: var(--black);
}

.CardTitleDark {
  composes: CardTitle;
}

.CardDescription {
  margin: 1rem 0;
  color: var(--grey-300);
}

.CardDescriptionLight {
  composes: CardDescription;
  color: var(--black);
}

.CardDescriptionDark {
  composes: CardDescription;
  color: var(--white);
}

.CardAvatarArea {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.CardAvatarImage {
  max-width: 32px;
  max-height: 32px;
}

.CardAvatarName {
  composes: CommonText;
  margin-left: 10px;
  color: var(--white);
}

.CardAvatarNameDark {
  composes: CommonText;
  composes: CardAvatarName;
  color: var(--white);
}

.CardAvatarNameLight {
  composes: CommonText;
  composes: CardAvatarName;
  color: var(--black);
}
