.FeedPaginatorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: none;
}

.RefreshIcon {
  margin-left: 0.8rem;
}
