@import 'src/styles/mediaQueries';

.FullPost {
  overflow: auto;
  width: 100%;
  height: 95vh;

  &.NoMedia {
    display: flex;
    flex-direction: column;
  }

  @include screens-above('lg') {
    display: flex;
    width: 100rem;
    max-width: 100rem;
    height: auto;
    max-height: 95vh;
    flex-direction: column;

    &.NoMedia {
      overflow: unset;
      width: 69rem;
      max-width: 69rem;
    }
  }
}
