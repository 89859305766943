@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.outer-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100%;

  @include screens-above('lg') {
    overflow: visible;
  }
}

.outer-wrapper.CustomBackground {
  background-color: var(--color-bg-1);
}

.outer-wrapper ::-webkit-scrollbar {
  display: none;
}

.wrapper {
  transition: transform 0.2s, opacity 0.2s;
}

.nav_mobile-active .wrapper {
  opacity: 0.5;
  transform: translate(25.5rem, 0);
}

.site-header {
  position: relative;
  z-index: z('profile-menu');
  display: flex;
  height: 6.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 1.4rem;

  @include screens-below('lg') {
    background-color: var(--color-bg-2);
    box-shadow: 0 1px 0 rgba(var(--color-rgb-black), 0.08);
  }
}

.site-header__mobile-nav {
  display: flex;
  width: 1.8rem;
  align-items: center;
  margin-left: 1.4rem;

  svg path {
    fill: var(--color-text-title);
  }
}

.nav {
  position: fixed;
  z-index: z('header');
  top: 0;
  bottom: 0;
  left: 0;
  width: 25.5rem;
  border-right: 1px solid var(--color-border);
  background: var(--color-bg-2);
  box-shadow: 0 0 0.9rem 0 rgba(var(--color-rgb-nav), 0.1);
  font-size: 1.4rem;
  overflow-y: hidden;
  transform: translate(-100%, 0);
  transition: width 0.2s ease, transform 0.2s ease;
  white-space: nowrap;
}

.nav:hover {
  overflow: auto;
}

.nav ul {
  padding: 0;
  margin: 0;
}

.nav ul:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-border);
}

.nav ul ul {
  background: var(--color-bg-1);
}

.nav ul ul a {
  height: 4.4rem;
}

.nav ul ul .nav__ico {
  width: 2.5rem;
  min-width: auto;
  height: 2.5rem;
  margin-left: 1.2rem;
}

.nav ul ul .nav__ico svg {
  width: 2.5rem;
  height: 2.5rem;
}

.nav ul ul .nav__text {
  margin-left: 0.8rem;
}

.nav .Menu_a_season2,
.nav .Menu_a_season2:hover {
  background-color: var(--color-bg-3);
  color: var(--color-text-main);
}

.nav__text__season2 {
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem / 1.3;
  font-weight: 400;
}

.nav__text__season2__wired {
  display: block;
  margin-top: 0.4rem;
  color: var(--color-text-title);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem / 1.1;
  font-weight: 900;
  text-transform: uppercase;
}

.nav a,
.nav svg,
.nav img {
  display: block;
}

.nav a {
  display: flex;
  height: 6.4rem;
  align-items: center;
  padding: 1.6rem 2rem 1.6rem 3rem;
  color: var(--color-text-main);
  text-decoration: none;
}

.nav a *[stroke='#5A5665'],
.nav a *[stroke='#CECCD1'] {
  stroke: var(--color-nav-icon);
}

.nav a *[fill='#5A5665'],
.nav a *[fill='#000'] {
  fill: var(--color-nav-icon);
}

.nav a:hover,
.nav .a_active {
  background: var(--color-bg-nav-active);
  color: var(--color-text-nav-active);
}

.nav a:hover *[stroke='#5A5665'],
.nav a:hover *[stroke='#CECCD1'],
.nav a:hover *[stroke='#F63A55'],
.nav .a_active *[stroke='#5A5665'],
.nav .a_active *[stroke='#F63A55'],
.nav .a_active *[stroke='#CECCD1'] {
  stroke: var(--color-text-nav-active);
}

.nav a:hover *[fill='#5A5665'],
.nav a:hover *[fill='#F63A55'],
.nav .a_active *[fill='#5A5665'],
.nav .a_active *[fill='#F63A55'],
.nav a:hover *[fill='#000'],
.nav .a_active *[fill='#000'] {
  fill: var(--color-text-nav-active);
}

.nav a:hover *[fill='#FFFFFF'],
.nav .a_active *[fill='#FFFFFF'] {
  fill: var(--color-brand-border);
}

.nav a *[fill='#FFF']:not(mask) {
  fill: none;
}

.nav__item {
  padding: 0;
  margin: 0;
  background-color: var(--color-bg-2);
  list-style: none;
}

.nav__item:last-child {
  margin-bottom: 0;
}

.nav__item_with-inner {
  position: relative;
}

.nav__item_inner {
  position: relative;
  background-color: var(--color-bg-3);
}

.nav__item_sublinks_marker {
  border-top: 0.5rem solid var(--color-text-main);
  border-right: 0.5rem solid transparent;
  border-left: 0.5rem solid transparent;
  content: '';
}

.nav__item_with-inner > ul {
  display: none;
}

.nav__item_active > a::after {
  transform: rotate(180deg);
}

.nav__item_active > ul {
  display: block;
}

.nav__item_inner_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.nav__item_inner_content {
  display: flex;
  align-items: center;
}

.nav__item_mobile_and_fullscreen {
  display: block;
}

.nav__ico {
  display: block;
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
}

.nav__ico svg {
  margin: auto;
}

.nav__text {
  margin-left: 2rem;
  transition: 0.2s;
}

.nav_hover .nav__text {
  opacity: 1;
  visibility: visible;
}

.nav_mobile-active .nav {
  position: absolute;
  transform: translate(0, 0);
}

@include screens-above('lg') {
  .wrapper {
    transition: none;
  }

  .nav_mobile-active .wrapper {
    opacity: 1;
    transform: translate(0, 0);
  }

  .site-header__mobile-nav,
  .site-header_mobile-logo {
    display: none;
  }

  .inner-wrapper {
    padding: 0 0 0 9.5rem;
    transition: none;
  }

  .site-header {
    z-index: z('header');
    height: 7.3rem;
    justify-content: flex-end;
    padding: 1.9rem 3.4rem;
  }

  .site-header > div {
    width: auto;
  }

  .nav {
    position: absolute;
    z-index: z('navigation');
    width: 9.5rem;
    transform: translate(0, 0);
    transition: width 0.2s ease;
  }

  .nav__text {
    opacity: 0;
    visibility: hidden;
  }

  .nav__item_mobile_and_fullscreen {
    display: none;
  }
}

@include screens-above('xxl') {
  .nav {
    width: 25.5rem;
  }

  .nav .nav__text {
    opacity: 1;
    visibility: visible;
  }

  .inner-wrapper {
    overflow: auto;
    padding: 0 0 0 25.5rem;
  }

  .nav__item_mobile_and_fullscreen {
    display: block;
  }
}

.nav_hover {
  width: 25.5rem;
}

.nav_item_separator {
  height: 0;
  border-bottom: 0.1rem solid var(--color-border);
}

.suspense-content {
  display: flex;
  align-items: center;
}
