.CommonTrimText {
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.TextArea {
  display: flex;
  height: 23px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.Text {
  composes: CommonTrimText;
  margin: 0;
  color: var(--black);
}

.Icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

.IconWhite {
  & path {
    fill: var(--white);
  }
}

.IconBlack {
  & path {
    fill: var(--black);
  }
}

.IndicatorArea {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--red-20);
}

.IndicatorCurrent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--red-60);
}
