.TypeFluid {
  width: 100% !important;
  height: auto !important;
}

.Squared {
  border-radius: 0;
}

.Rounded {
  border-radius: 1rem;
}

.Circle {
  border-radius: 100%;
}

.Overlayer {
  position: absolute;
  z-index: z('image');
  object-fit: cover;
  object-position: 50% 50%;
}

.Interactive {
  cursor: pointer;

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}
