@import 'src/styles/zIndex';

.CommonAbsolutePositioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CommonImage {
  position: relative;
  width: 69px;
  height: 104px;
}

.ImageGroup {
  display: inline-flex;
  flex-direction: row;

  &.NonClickable * {
    cursor: unset;

    &:focus {
      outline: unset;
    }

    &:active {
      outline: unset;
    }
  }
}

.ImageItem {
  composes: CommonImage;
  margin-right: 16px;
  object-fit: cover;
  object-position: 50% 50%;
}

.ImageItemMore {
  composes: CommonImage;
  composes: ImageItem;
  margin-right: 0;

  &.ImageItemMoreClickable {
    cursor: pointer;

    &:focus {
      outline: 1px dashed var(--red-60);
    }

    &:active {
      outline: 1px dashed var(--red-60);
    }
  }
}

.ImageOverlay {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  background: rgba(0, 0, 0, 0.5);
}

.ImageContent {
  composes: CommonAbsolutePositioning;
  z-index: z('image-content');
  display: flex;
  align-items: center;
  justify-content: center;

  & .ImageContentText {
    color: var(--white);
  }
}
