.listItem {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2.4rem;
  color: var(--color-text-main);
}

.itemButton {
  display: flex;
  border: 0;
  background-color: transparent;
  outline: 0;
  text-align: start;

  &:hover {
    cursor: pointer;
  }
}

.itemInformation {
  margin-left: 0.8rem;
  color: var(--color-text-main);

  span {
    display: block;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.activeDate {
  color: var(--color-brand-main);
  font-weight: bold;
}

.redDotContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0.9rem;
  border: 0;
  margin-left: auto;
  background-color: transparent;
  outline: 0;

  &:hover {
    cursor: pointer;
  }
}

.redDot {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--color-brand-main);
  border-radius: 50%;
}
