@use 'sass:math';

.Message {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  &.Show {
    display: block;
  }

  &.Success {
    border: 1px solid var(--utility-green-100);
    background: #eeffe8;
    color: var(--utility-green-100);
  }

  &.Success.Glow {
    box-shadow: 0 8px 24px rgba(50, 191, 0, 0.16);
  }

  &.Error {
    border: 1px solid var(--red-60);
    background: #ffeaed;
    color: var(--red-60);
  }

  &.Error.Glow {
    box-shadow: 0 8px 24px rgba(255, 89, 100, 0.16);
  }
}

$MessageCloseButtonLineLength: 10px;
$MessageCloseButtonLineWidth: 2px;
$SquareRootOfTwo: math.sqrt(2);
$MessageCloseButtonLineLeft: math.div(math.div($MessageCloseButtonLineLength, 2), $SquareRootOfTwo);

.MessageCloseButton {
  position: absolute;
  top: 5px;
  right: ($MessageCloseButtonLineLength + 5px);
  opacity: 0.3;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &::before {
    position: absolute;
    left: $MessageCloseButtonLineLeft;
    width: $MessageCloseButtonLineWidth;
    height: $MessageCloseButtonLineLength;
    background-color: var(--black);
    content: ' ';
    transform: rotate(45deg);
  }

  &::after {
    position: absolute;
    left: $MessageCloseButtonLineLeft;
    width: $MessageCloseButtonLineWidth;
    height: $MessageCloseButtonLineLength;
    background-color: var(--black);
    content: ' ';
    transform: rotate(-45deg);
  }
}
