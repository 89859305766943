$vertical-card-height: 356px;
$vertical-card-width: 226px;

$horizontal-card-height: 212px;
$horizontal-card-width: 468px;

.InfoBox {
  box-sizing: border-box;
  padding: 0;

  & * {
    box-sizing: border-box;
  }

  &.Vertical {
    max-width: $vertical-card-width;
    max-height: $vertical-card-height;
    flex-direction: column;

    .InfoBoxImage {
      max-height: 120px;
    }
  }

  &.Horizontal {
    max-width: $horizontal-card-width;
    max-height: $horizontal-card-height;
    flex-direction: row-reverse;

    .InfoBoxImage {
      min-width: 140px;
    }
  }
}

.InfoBoxContent {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;

  .InfoBoxTitle {
    overflow: hidden;
    min-height: 21px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .InfoBoxDescription {
    overflow: hidden;
    flex: 1;
    margin-top: 0;
  }
}

.InfoBoxImage {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
