@import 'src/styles/zIndex';

.Icon {
  path {
    fill: var(--utility-green-100);
  }

  &.Highlighted {
    path {
      fill: var(--red-60);
    }
  }
}

.Title {
  margin: 0.5rem 0 1.5rem;
}
