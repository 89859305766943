.Wrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;

  &:active {
    outline: 1px dashed var(--red-60);
  }

  &:focus {
    outline: 1px dashed var(--red-60);
  }
}

.IconWrapper {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.UnreadIndicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border: 2px solid var(--white);
  background-color: var(--red-60);
  border-radius: 100%;
}

.Icon {
  &.Active {
    & path {
      fill: var(--grey-600);
    }
  }

  &.Inactive {
    & path {
      fill: var(--grey-400);
    }
  }
}
