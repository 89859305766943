@import 'src/styles/mediaQueries';

.VideoModal {
  width: 100%;

  @include screens-above('lg') {
    padding: 0;
    background: none;
  }
}

.VideoTitle {
  margin: 0;
  color: var(--color-black);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 2.8rem;
  line-height: 3rem;

  @include screens-above('lg') {
    display: none;
  }
}
