@import 'src/styles/mediaQueries';

.PinnedFeedContainer {
  margin-bottom: 0.8rem;

  @include screens-above('md') {
    margin-bottom: 4.8rem;
  }
}

.PinnedFeedContainer > div {
  display: grid;
  width: calc(100% + 1.6rem);
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;

  @include screens-above('md') {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
}

.PinnedFeedContainer :global(.raf-load-more-button) {
  display: none;
}
