.ClickableCard {
  width: 100%;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-100);
  }

  &:focus,
  &:active {
    border: 1px solid var(--grey-600);
  }

  &.Contained {
    width: 354px;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}

.CommonText {
  display: -webkit-box;
  overflow: hidden;
  margin: 0;
  -webkit-box-orient: vertical;
  font-size: 14px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}

.Name {
  composes: CommonText;
  color: var(--grey-600);
}

.Amount {
  composes: CommonText;
  color: var(--grey-500);

  & span {
    color: var(--grey-400);
  }
}

.Selection {
  display: flex;
  align-items: center;
  justify-content: center;
}
