.CommonBadgeLayout {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: var(--black);

  &.BadgeClickable:focus {
    outline: 1px dashed var(--red-60);
  }
}

.BadgeIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.BadgeText {
  margin: 0;
  color: var(--black);
}

.BadgeTextDark {
  margin: 0;
  color: var(--white);
}

.BadgeClickable {
  cursor: pointer;
}

.BadgeNoBackground {
  composes: CommonBadgeLayout;
  background-color: transparent !important;
}

.BadgeDark {
  composes: CommonBadgeLayout;
  backdrop-filter: blur(48px);
  background: rgba(0, 0, 0, 0.24);
}

.BadgeRegular {
  composes: CommonBadgeLayout;
  background-color: var(--grey-200);
}

.BadgeCommon {
  composes: BadgeRegular;
}

.BadgeRare {
  composes: CommonBadgeLayout;
  background-color: var(--rare-20);
}

.BadgeLegendary {
  composes: CommonBadgeLayout;
  background-color: var(--legendary-200);
}

.BadgeFeatured {
  composes: CommonBadgeLayout;
  background-color: var(--featured-20);
}

.BadgeSoldOut {
  composes: CommonBadgeLayout;
  background-color: var(--grey-600);
}

.BadgeComingSoon {
  composes: CommonBadgeLayout;
  background-color: var(--featured-60);
}

.BadgeUnavailable {
  composes: CommonBadgeLayout;
  background-color: var(--grey-400);
}

.BadgeHistoric {
  composes: CommonBadgeLayout;
  background-color: var(--grey-200);
}
