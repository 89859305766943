@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.MediaSelectorContainer {
  overflow: auto;
  padding-top: 1.6rem;
  margin-top: auto;
}

@include screens-below('md') {
  .MediaSelectorContainer {
    margin-bottom: 20rem;
  }
}

.MediaSelectorContainer input {
  display: none;
}

.MediaContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.UploadButton {
  display: flex;
  width: 9.5rem;
  height: 9.5rem;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--color-gray);
  margin-right: 0.8rem;
  margin-bottom: 0.5rem;
  margin-left: 0.1rem;
  background: var(--color-bg-1);
  cursor: pointer;
}

.UploadButton svg {
  width: 2.5rem;
  fill: var(--color-button-gray);
}

.ThumbnailContainer {
  position: relative;
  padding-right: 0.8rem;
}

.Thumbnail {
  position: relative;
  overflow: hidden;
  width: 9.5rem;
  height: 9.5rem;
  background-color: var(--color-bg-1);
}

.ThumbnailProgress {
  position: absolute;
  z-index: z('base');
  bottom: 0;
  width: 100%;
  background-color: var(--color-brand-main);
  opacity: 0.72;
}

.Thumbnail img {
  width: auto;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.Thumbnail svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ThumbnailVideoIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CloseButton {
  position: absolute;
  z-index: z('base');
  top: -1rem;
  right: -0.2rem;
  min-width: 2.5rem;
  height: 2.5rem;
  border: 0;
  background-color: transparent;
  outline: 0;
}

.CloseButton svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--color-brand-main);
}
