@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.BackButtonWrapper {
  width: 100%;
}

.FallbackContentSpace {
  height: 5rem;
}

.StickyWrapper {
  position: fixed;
  z-index: z('popover');
  top: 0;
  left: 0;
}

.BackButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  border: 0;
  background-color: var(--color-bg-1);
  color: var(--color-text-main);
  cursor: pointer;
  outline: 0;
  text-align: center;
}

.BackButton svg {
  width: 1.5rem;
  margin-right: 1.6rem;
  fill: var(--color-text-main);
  vertical-align: middle;
}
