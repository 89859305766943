.Toggle {
  position: relative;
  display: inline-block;
  width: 4.8rem;
  height: 2.4rem;
}

.Toggle input {
  display: none;
}

.Slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-200);
  border-radius: 34px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider::before {
  position: absolute;
  bottom: 0.3rem;
  left: 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: var(--grey-400);
  border-radius: 50%;
  content: '';
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Toggle input:checked + .Slider {
  border: 1px solid var(--red-60);
  background-color: var(--red-20);
}

.Toggle input + .Slider {
  border: 1px solid var(--grey-400);
  background-color: var(--grey-200);
}

.Toggle input:focus + .Slider {
  box-shadow: 0 0 1px var(--red-20);
}

.Toggle input:checked + .Slider::before {
  background-color: var(--red-60);
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
