@import 'src/styles/mediaQueries';

.Checkbox {
  display: flex;
  max-height: 4.5rem;
  align-items: center;
}

.Checkbox input[type='checkbox'] {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.Checkbox input[type='checkbox']:focus + span {
  outline: 1px solid var(--color-brand-main);
}

.Checkbox input[type='checkbox'] + span {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--color-border);
  margin: -1px 0.3rem 0 0;
  background: var(--color-white) left top no-repeat;
  cursor: pointer;
  vertical-align: middle;
}

.Checkbox input[type='checkbox']:checked + span {
  background: var(--color-brand-main) -1.6rem top no-repeat;
}

.Checkbox__disabled input[type='checkbox'] + span {
  background-color: var(--color-shadow);
  cursor: not-allowed;
}

.Checkbox_modal {
  align-items: left;
  justify-content: flex-start;
}

@include screens-above('md') {
  .Checkbox_modal {
    align-items: center;
    justify-content: center;
  }
}
