.LoginModuleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ServerDownInfo {
  color: var(--color-brand-main);
}

.LoginWithoutGoogle {
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  text-decoration: none;
  color: var(--color-brand-main);
  margin-top: 2rem;
  display: inline-block;
}

.LoginWithoutGoogle:hover {
  cursor: pointer;
  text-decoration: underline;
}
