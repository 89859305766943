@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.FeedModal {
  position: fixed;
  z-index: z('modal', 'lvl-2');
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-rgb-black), 0.8);

  @include screens-above('lg') {
    padding: 3.6rem;
  }
}

.CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.6rem;
  border: 0;
  background-color: transparent;
  color: var(--color-white);
  cursor: pointer;
  outline: 0;
}

.CloseButton svg {
  width: 3rem;
  fill: transparent;
}

.ContainerClass {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-2);

  @include screens-above('lg') {
    width: initial;
    height: initial;
  }
}
