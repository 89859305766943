@import 'src/styles/mediaQueries';

.Row {
  margin-bottom: 1.4rem;
}

.Row_section {
  padding-top: 2.7rem;
  border-top: 0.1rem solid var(--color-border);
  margin: 1.4rem 0;
}

.Row_flexCentered {
  align-items: center;
}

.Row_section:first-of-type {
  border-top: none;
}

.Row_unleash {
  display: grid;
  min-height: 8rem;
  padding: 1.6rem;
  border: 0.1rem solid var(--color-disabled-text);
  margin-bottom: 0;
  font: 1.4rem/2rem 'Source Sans Pro', sans-serif;
  grid-template: auto / 5.6rem 1fr;
}

.Row_paymentDisplay {
  margin-bottom: 1rem;
}

.Row_small_section {
  padding-top: 1.4rem;
  border-top: 0.1rem solid var(--color-border);
  margin: 1.4rem 0;
}

.Row_hasNote {
  margin-bottom: 0;
}

.Row_vertical {
  flex-direction: column;
}

.Row_horizontal {
  flex-direction: row;
}

.Row_fullwidth {
  width: 100%;
}

@include screens-above('md') {
  .Row_unleash {
    display: flex;
    padding: 0 2.4rem;
    font: 1.4rem / 5.5 'Source Sans Pro', sans-serif;
  }
}

@include screens-above('lg') {
  .Row {
    display: flex;
  }

  .Row_withLeftPadding {
    padding-left: 1.7rem;
  }

  .Row_section {
    padding-top: 4.1rem;
  }
}
