@import 'src/styles/zIndex';

.Media {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  outline: 0;
}

.MediaTwoCol {
  width: 49.7% !important;
}

.MediaThreeCol {
  width: 33% !important;
}

.MediaThreeColBelow {
  width: 33% !important;
  height: 13rem;
  object-fit: cover;
}

.TopMedia {
  height: 22.5rem !important;
}

.LastMediaOverlayContainer {
  position: relative;
  display: inline-block;
  width: 33% !important;
  height: 13rem;
}

.LastMediaOverlay {
  position: absolute;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 13rem;
  background:
    linear-gradient(
      0deg,
      rgba(var(--color-rgb-dark-purple), 0.5),
      rgba(var(--color-rgb-dark-purple), 0.5)
    );
}

.RemainingMediaText {
  position: absolute;
  z-index: 10;
  top: 4.3rem;
  left: 0;
  width: 100%;
  color: var(--color-white);
  font-size: 3.4rem;
  font-style: italic;
  font-weight: 800;
  text-align: center;
}

.IsMobile {
  height: 16rem;

  &.MediaTwoCol,
  &.MediaThreeCol,
  &.MediaThreeColBelow {
    display: none;
  }
}

.OverIconContainer {
  position: absolute;
  z-index: z('base');
  top: 0.8rem;
  left: 0.8rem;
}

.OverIconContainer > div {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin-right: 0.4rem;
  backdrop-filter: blur(4.8rem);
  background: rgba(var(--color-rgb-black), 0.24);
  text-align: center;
  vertical-align: middle;
}

.OverIconContainer svg {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  fill: var(--color-white);
  text-align: center;
  transform: translateY(-50%);
}
