@import 'src/styles/mediaQueries';

.h {
  color: var(--color-text-main);
  line-height: 1;

  --h1-font-size: 5.6rem;
  --h2-font-size: 4rem;
  --h3-font-size: 3rem;
  --h4-font-size: 2.8rem;
  --h5-font-size: 2.5rem;
  --h6-font-size: 2.2rem;

  @include screens-above('md') {
    --h1-font-size: 13.2rem;
    --h2-font-size: 6.4rem;
    --h3-font-size: 3.4rem;
    --h4-font-size: 3rem;
    --h5-font-size: 2.5rem;
    --h6-font-size: 2.2rem;
  }
}

.h1 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h1-font-size);
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.h2 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h2-font-size);
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.h3 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h3-font-size);
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0;
  text-transform: uppercase;
}

.h4 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h4-font-size);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.h5 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h5-font-size);
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.01em;
}

.h6 {
  font-family: 'Prompt', sans-serif;
  font-size: var(--h6-font-size);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.01em;
}
