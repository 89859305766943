@import 'src/styles/mediaQueries';
@import 'src/styles/zIndex';

.CardModule {
  position: relative;
  height: 100%;
  min-height: 20.5rem;
  background-color: var(--color-bg-2);
  box-shadow: 0 0 1.7rem var(--color-shadow);

  @include screens-above('md') {
    display: flex;
    max-width: 46.8rem;
  }

  &:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 1.2rem;
  }
}

.CardModule_reverse {
  flex-direction: row-reverse;
}

.CardModule_content {
  display: flex;
  height: calc(100% - 22.6rem);
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem;

  @include screens-above('md') {
    z-index: z('base');
    width: 50%;
    height: auto;
    padding: 2.4rem;
    background-color: var(--color-bg-2);
  }
}

.CardModule_title {
  display: block;
  margin-bottom: 1.2rem;
  color: var(--color-text-title);
  font-family: 'Prompt', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.4;
  text-transform: uppercase;
}

.CardModule_text {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-text-title);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  line-height: 1.4;
}

.CardModule_btn {
  width: 100%;
  height: auto;
  padding: 1rem 2.4rem;
  margin-top: 1.6rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: initial;

  @include screens-above('md') {
    width: initial;
  }
}

.ImageWrapperLeft,
.ImageWrapper {
  position: relative;
  overflow: hidden;
  height: 22.6rem;
  text-align: center;

  @include screens-above('md') {
    display: flex;
    width: 33%;
    height: auto;
    justify-content: flex-start;
  }
}

.ImageWrapperLeft img,
.ImageWrapper img {
  width: 100%;

  @include screens-above('md') {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 26.1rem;
    height: auto;
  }
}
