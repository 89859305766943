@import 'src/styles/zIndex';

.CommonAbsolutePositioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.LegendWrapper {
  position: relative;
  width: 304px;
  height: 360px;
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    outline: 1px dashed var(--red-60);
  }

  & * {
    box-sizing: border-box;
  }
}

.LegendImage {
  composes: CommonAbsolutePositioning;
  z-index: z('base');
  background: linear-gradient(9deg, rgba(84, 84, 84, 1) 0%, rgba(255, 255, 255, 0) 100%);
  object-fit: cover;
  object-position: 50% 50%;
}

.LegendPlaceholder {
  composes: CommonAbsolutePositioning;
  composes: LegendImage;
  z-index: z('image-content');
  display: flex;
  align-items: center;
  justify-content: center;
}

.LegendImageOverlay {
  composes: CommonAbsolutePositioning;
  z-index: z('image');
  background: linear-gradient(9deg, rgba(84, 84, 84, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.LegendContent {
  composes: CommonAbsolutePositioning;
  z-index: z('image-content');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}

.LegendBadge {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--red-60);
  color: var(--white);
}

.LegendBadgeIcon {
  & path {
    fill: var(--white);
  }
}

.LegendBadgeText {
  overflow: hidden;
  max-width: 260px;
  margin: 0;
  margin-left: 10px;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LegendFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.LegendTitle {
  overflow: hidden;
  max-width: 260px;
  margin: 0.5rem 0;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LegendHouseWrapper {
  display: flex;
  flex-direction: row;
}

.LegendHouseName {
  overflow: hidden;
  max-width: 260px;
  margin: 0.5rem 0;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
}
