@import 'src/styles/mediaQueries';

.FeedPostText {
  width: 100%;
  max-width: 630px;
  word-wrap: break-word;
}

.FeedPost {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border: 1px solid var(--color-gray);
  margin-bottom: 0.8rem;
  background-color: var(--color-bg-2);
  cursor: pointer;

  @include screens-above('lg') {
    flex-direction: row;
    padding: 1.6rem 1.6rem 0.8rem 1.6rem;
    margin-bottom: 1.6rem;
  }
}

.FeedPostContent {
  flex: 1;
}

.PostActivityContainer {
  @include screens-above('lg') {
    padding: 0.8rem;
  }
}

.CoinAnimation {
  vertical-align: text-top;
}

.PostTop {
  display: flex;
}

.AvatarContainer {
  padding-right: 0.8rem;
}

.TopCenterContainer {
  flex: 1;
  align-self: center;
}

.Title {
  color: var(--color-text-title);
  font: 1.4rem 'Open Sans', sans-serif;
  line-height: 2rem;
}

.TitleName {
  font-weight: 700;
}

.TitleBounty {
  color: var(--color-brand-main);
}

.Date {
  color: var(--color-button-gray);
  font: 1.2rem 'Open Sans', sans-serif;
}

.MenuIcon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.6rem;
  border: 0;
  margin-left: 0.4rem;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  text-align: center;
  vertical-align: middle;
}

.MenuIcon svg {
  width: 0.4rem;
  fill: var(--color-button-gray);
  vertical-align: middle;
}

.PostContent {
  overflow: hidden;
  margin-top: 0.8rem;
  color: var(--color-text-main);
  font: 1.4rem 'Open Sans', sans-serif;
}

.TopRightContainer {
  position: relative;
  display: flex;
  max-height: 4rem;
  align-items: center;
}

.PostMenu {
  position: absolute;
  z-index: 2;
  top: 4.2rem;
  right: -1rem;
}
