@import 'src/styles/mediaQueries';

.Button {
  display: inline-block;
  min-width: 11.4rem;
  height: 3.4rem;
  padding: 0;
  border: 1px solid var(--color-brand-button);
  background-color: var(--color-brand-button);
  border-radius: 0;
  color: var(--color-white);
  cursor: pointer;
  font: 1rem / 3.4 'Source Sans Pro', sans-serif;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.Button:hover {
  opacity: 0.9;
}

.Button:disabled {
  border: var(--color-disabled-background) 1px solid;
  background-color: var(--color-bg-2);
  color: var(--color-disabled-text);
  cursor: not-allowed;
}

.Button_big {
  min-width: 17rem;
  height: 4.3rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 4.3rem;
}

.Button_small {
  min-width: 6.8rem;
}

.Button_reverse {
  padding: 0 2em;
  background-color: var(--color-bg-2);
  color: var(--color-brand-main);
}

.Button_gray {
  border-color: var(--color-light-gray);
  background-color: var(--color-lighter-bg);
  color: var(--color-text-main);

  &:disabled {
    background-color: var(--color-disabled-background);
    color: var(--color-disabled-text);
  }
}

.Button_green {
  border-color: var(--color-green2-bg);
  background-color: var(--color-lighter-bg);
  color: var(--color-green2-bg);

  &:disabled {
    background-color: var(--color-disabled-background);
    color: var(--color-disabled-text);
  }
}

.Button_bare {
  border-color: var(--color-light-gray);
  background: none;
  color: var(--color-button-gray);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: none;
}

.Button_formbtn {
  display: inline-block;
  height: 4.3rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 4.3rem;
}

.Button_FullWidth {
  display: block;
  width: 100%;
  margin: 0;
  font-size: 1.2rem;
}

.Button_mobile {
  width: 100%;
}

.Button_block {
  margin: 0 0 2.5rem;
}

.Button_inline:not(:last-child) {
  margin-right: 1em;
}

.Button_ref {
  height: 4.3rem;
  padding: 0 2.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  line-height: 4.3rem;
}

.Button_bankingConfirm {
  width: 100%;
  height: 4.3rem;
  font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
  letter-spacing: 0.1rem;
}

.Button_modal {
  display: inline-block;
  min-width: 12.3rem;
  height: 4.3rem;
  padding: 0 2.1rem;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 4.3rem;
}

.Button_close {
  width: 1.5rem;
  min-width: 3rem;
  height: 2.4rem;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
}

.Button_submitNext {
  width: 100%;
  height: 4.3rem;
  font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
  letter-spacing: 0.1rem;
}

.Button_addReimburseStep {
  width: 100%;
  border-color: var(--color-button-gray);
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  background-color: var(--color-bg-2);
  color: var(--color-button-gray);
  font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
}

.Button_loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Button_icon {
  min-width: initial;
  background-color: transparent;
  color: initial;
  font: inherit;
}

.Button_noOutline {
  border: 0;
  outline: none;
}

.Button_noOutline:focus {
  outline: none;
}

.Button_noOutline::-moz-focus-inner {
  border: 0;
}

.Button_loading > div {
  margin-right: 0.8rem;
}

@include screens-above('md') {
  .Button_submitNext {
    width: 17rem;
    margin-top: 1.7rem;
  }

  .Button_addReimburseStep {
    width: 100%;
    min-width: 65.5rem;
    max-width: 67.1rem;
    margin-top: 1.7rem;
    margin-bottom: 8.5rem;
    color: var(--color-button-gray);
    font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
  }

  .Button_spaced {
    margin: 1.7rem;
  }

  .Button_bankingConfirm {
    width: 17rem;
    max-width: 65.5rem;
    height: 4.3rem;
    padding: 0.4rem;
    font: 700 1.2rem / 1.3 'Open Sans', sans-serif;
    letter-spacing: 0.1rem;
  }
}
